const digitRegExp = /[0-9]/;

export function OnlyNumberWithZeroMask(value) {

  const arr = value.split('').map(char => {
    if (digitRegExp.test(char) && value >= 0) {
      return digitRegExp;
    }
  });

  return arr;
}
