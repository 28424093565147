import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';

export interface LocalStorageData {
  [proposalDraftKey: string]: LocalStorageAgentData;
}

export interface LocalStorageAgentData {
  proposal?: any;
  jumpSteps?: any[];
  proposalExpirationDate?: string;
  dropdownItems?: DropdownItemsStore[];
  routeStatus?: RouteData[];
}

export interface RouteData {
  routeName: string;
  currentData?: any;
  step: number;
  subStep: number;
}

export class SessionInformation {
  public name: string;
  public sessionToken: string;
}

export class DropdownItemsStore {
  public key: string;
  public items: Item[];
}
