<h3 fxLayoutAlign="center">Editar Grupo Econômico por Conveniência</h3>
<form [formGroup]="formGroup" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="column">
    <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
  </div>

  <div fxLayout="column" fxFlex="50" fxLayoutAlign="center center">
    <h5 style="text-align: center">
      Clique no botão abaixo para enviar o arquivo com todos os CNPJ's
      participantes do grupo
    </h5>
    <div fxLayout="row" fxLayoutGap="10px">
      <button
        class="fd-button"
        matTooltip="Upload do arquivo com cnpjs do grupo econômico"
        (click)="openFileExplorer()"
      >
        <div class="d-flex justify-content-center">
          <fa-icon icon="file-upload" size="1x"></fa-icon> Upload
        </div>
      </button>
      <div fxFlex="10" fxFlexAlign="center">
        <fa-icon
          class="fd-icon"
          icon="download"
          size="1x"
          matTooltip="Download do modelo de arquivo"
          (click)="downloadTemplate()"
        ></fa-icon>
      </div>
    </div>
    <p>{{ fileName }}</p>
    <input
      type="file"
      class="hiddenUpload"
      #hiddenUpload
      (change)="setFile($event.target.files)"
      name="upload-start"
      accept="*/*"
    />
  </div>

  <div>
    <fd-button
      fdSecondary
      [content]="'Cancelar'"
      (trigger)="close()"
    >
    </fd-button>
    <fd-button
      [content]="'Salvar'"
      (trigger)="save()"
    >
    </fd-button>
  </div>
</form>
