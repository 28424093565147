import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Router } from '@angular/router';
import { Messages } from 'src/app/order/messages/order.messages';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  navigateWithParentTo(parentRoute: string, childRoute: string, replaceUrl: boolean = false): void {
    this.router.navigate([`/${parentRoute}/`.concat(childRoute)], { replaceUrl: replaceUrl });
  }

  navigateTo(path: string, replaceUrl: boolean = false): void {
    this.router.navigate([path], { replaceUrl: replaceUrl });
  }

  navigateWithQueryParamsTo(path: string, queryParams: any, replaceUrl: boolean = false): void {
    this.router.navigate([path], { replaceUrl: replaceUrl, queryParams });
  }

  navigateToHome(reload: boolean = true) {
    if(reload){
      this.document.location.href = '/'
    }
    else{
      this.router.navigate(['/']);
    }
  }

  navigateToLogin(reload: boolean = true, showTimedOutModal: boolean = true) {
    if(showTimedOutModal){
      this.dialogService.openDialog(Messages.SESSION_TIMED_OUT);
    }
    if(reload){
      this.document.location.href = '/login'
    }
    else{
      this.router.navigate(['/login']);
    }
  }



}
