import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SimulationService } from '../services/external/simulation/simulation.service';
import { DataStoreService } from '../store/data-store.service';

@Injectable({
  providedIn: 'root'
})
export class OrderStepGuard implements CanActivate {

  public validated$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private dataStoreService: DataStoreService,
    private simulationService: SimulationService
  ) {

  }

  canActivate(currentRoute: ActivatedRouteSnapshot): Observable<boolean> {

    return of(currentRoute)
      .pipe(map((route: ActivatedRouteSnapshot) => {
        let canActivate = true;
        let cpfCnpj: string;
        let cnaeParam: string;
        let registrationCode: string;
        let uf: string;
        let queryParams = {};
        
        return canActivate;
      }));
  }

}
