import { Component, Input, OnInit } from '@angular/core';
import { getValueWithDecimalPlaces, getValueWithDecimalPlacesBrazillianFormat} from 'src/app/order/utils/decimal-places';
import { TableColumnsModel } from './models/table-columns.model';

@Component({
  selector: 'app-simulation-table',
  templateUrl: './simulation-table.component.html',
  styleUrls: ['./simulation-table.component.scss']
})
export class SimulationTableComponent implements OnInit {

  @Input() public dataSource: any;
  @Input() public objectProperties: TableColumnsModel[] = [];
  @Input() public displayedColumns: string[] = [];
  @Input() public tableName: string = 'Taxas MDR';

  ngOnInit() {
    if (this.displayedColumns.length <= 0) {
      this.displayedColumns = this.objectProperties.map(o => o.name);
    }
  }

  public resolveValue(val, prop: TableColumnsModel) {
    if (prop.type === 'fee') {
      if(this.isCurrency(val)){
        return val[prop.name];   
      }
      if(val[prop.name]){
        if(getValueWithDecimalPlaces(val[prop.name]) == undefined){
          return "--";
        }
        return val[prop.name]  ? getValueWithDecimalPlaces(val[prop.name]) + ' %' : '';
      }
    } else if (prop.type === 'string') {
      return val[prop.name] ? val[prop.name] : prop.name === 'flag'? 'Todas' : '';
    }else if (prop.type === 'currencyOrTax'){
      if(this.isTax(val)){
        return val[prop.name];   
      }
      return val[prop.name]  ? 'R$ ' + getValueWithDecimalPlaces(val[prop.name])  : '';
    }
  }

  private isCurrency(val){
    return val["type"] === "CURRENCY";
  }

  private isTax(val){
    return val["type"] === "TAX";
  }


}
