export interface SimulationModel {
  serviceContract?: number;
  channel?: string;
  subChannel?: string;
  agentChannel?: string;
  institution?: string;
  channelType?: string;
  clientInformation?: ClientInformationModel;
  simulationOfferInformation?: SimulationOfferInformationModel;
}

export class ClientInformationModel {
  cpfCnpj: string;
  billingValue: string;
  cnaeNumber: number;
  state: string;
  personType: string;

  /**
   *
   */
  constructor() {
    this.cpfCnpj = '';
    this.billingValue = '';
    this.cnaeNumber = 0;
    this.state = '';
    this.personType = '';
  }

}

export class SimulationOfferInformationModel {
  fees: SimulationFeesModel[];
  status: SimulationOfferStatusEnum;
  order: number;
}

export class SimulationFeesModel {
  sentPrepaymentFee?: number;
  suggestedPrepaymentFee?: number;
  minimumFactorFee?: number;
  suggestedFactorFee?: number;
  factorType?: number;
  serviceFees: SimulationServiceFeesModel[];
}

export enum SimulationOfferStatusEnum {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED"
}

export class SimulationServiceFeesModel {
  sentMdrFee?: number;
  minimumMdrFee?: number;
  suggestedMdrFee?: number;
  sentMdrFlexFee?: number;
  minimumMdrFlexFee?: number;
  suggestedMdrFlexFee?: number;
  idService: string;
  modality: string;
  transactionDescription: string;
}
