<div class="fd-accreditation-form__container">
  <div class="forms" fxLayout="column" fxLayoutAlign="center">
    <h4 class="fd-subtitle">{{"Consultar grupo econômico por conveniência" | uppercase}}</h4>
    <h5 class="fd-subtitle-left">{{"Para consulta do Grupo Econômico por Conveniência, busque por um dos campos abaixo:"}}</h5>
    <app-information-search (formGroupInfo)="getBasicInformation($event)"></app-information-search>
  </div>
  <div class="fd-accreditation-form__container__actions">
    <fd-button
      class="no-print"
      [content]="'Voltar para o simulador de taxas'"
      (trigger)="goBack()"
    >
    </fd-button>

    <fd-button
      class="no-print"
      [content]="'Consultar Grupo'"
      (trigger)="searchGroup()"
    >
    </fd-button>
  </div>

  <div class="fd-accreditation-table__container">
    <app-eventual-economical-group-table
      [dataSource]="eventualEconomicalGroups$ | async"
      (onDelete)="deleteGroup($event)"
      (onDownload)="downloadCnpjs($event)"
      (onEdit)="editGroup($event)">
    </app-eventual-economical-group-table>
  </div>
</div>
