import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrderModule } from './order/simulation.module';
import { FlightRequest } from './shared/helper/flight-request.interceptor';
import { JwtInterceptor } from './shared/helper/jwt.interceptor';
import { SharedComponentsModule } from './shared/shared-components.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    OrderModule,
    NgxSpinnerModule,
    AppRoutingModule,
    SharedComponentsModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass:JwtInterceptor,multi:true},
    { provide: HTTP_INTERCEPTORS, useClass: FlightRequest, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements OnInit {

  constructor() {
  }

  async ngOnInit() {
  }
}
