<div class="fd-accreditation-form__container">
  <div class="forms" fxLayout="column" fxLayoutAlign="center">
    <h4 class="fd-subtitle">{{"Criar Grupo Econômico por Conveniência" | uppercase}}</h4>
    <app-information (formGroupInfo)="getBasicInformation($event)"></app-information>
  </div>
  <div fxLayout="column">
    <h4 class="fd-subtitle">Informe os CNPJs participantes do grupo</h4>
    <div>
      <div fxLayout="column" fxFlex="50">
        <div>
          <ng-container  *ngFor="let formGroup of formGroupsCNPJ; let i = index">
            <form  [formGroup]="formGroup">
              <div class="fd-cnpj-form__container">
                <fd-input
                  [parentForm]="formGroup"
                  [field]="fields.cnpj"
                  (input)="handleInput(formGroup)"
                ></fd-input>
                <div fxFlexAlign="center" fxLayoutGap="20px">
                  <fa-icon
                    *ngIf="formGroup.value.delete"
                    class="fd-icon"
                    [ngClass]="{'fd-icon--disabled': disableCNPJs}"
                    [matTooltip]="disableCNPJs? '' : 'Excluir CNPJ da lista'"
                    icon="trash"
                    (click)="delete(i)"
                  >
                  </fa-icon>
                  <fa-icon
                    [ngClass]="{'fd-icon--check': formGroup.value.check, 'fd-icon--xmark': !formGroup.value.check}"
                    [matTooltip]="formGroup.value.check ? '' : 'CNPJ existente em grupo econômico'"
                    [icon]="formGroup.value.check? 'check' : 'xmark'"
                    *ngIf="formGroup.value.cnpj != '' && formGroup.value.check != null"
                  >
                  </fa-icon>
                </div>

              </div>
            </form>
          </ng-container>
        </div>
        <div fxLayoutAlign="end stretch">
          <div fxFlexAlign="center">
            <button class="fd-button" (click)="addNewCNPJ()" [ngClass]="{'fd-button--disabled': disableCNPJs}">
              <div class="d-flex justify-content-center">
                <fa-icon icon="plus-circle" size="1x"></fa-icon> ADICIONAR CNPJ
              </div>
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap="10px">
          <button
            class="fd-button"
            [ngClass]="{'fd-button--disabled': validCNPJ()}"
            matTooltip="Upload do arquivo com cnpjs do grupo econômico"
            (click)="openFileExplorer()"
            [disabled]="validCNPJ()"
          >
            <div class="d-flex justify-content-center">
              <fa-icon icon="user-lock" size="1x"></fa-icon> Importar
            </div>
          </button>
          <div fxFlex="10" fxFlexAlign="center">
            <fa-icon
              class="fd-icon"
              [ngClass]="{'fd-icon--disabled': validCNPJ()}"
              icon="download"
              size="1x"
              [matTooltip]="validCNPJ()? '' : 'Download do modelo de arquivo'"
              (click)="downloadTemplate()"
            ></fa-icon>
          </div>
        </div>
        <p>{{ fileName }}</p>
        <input
          type="file"
          class="hiddenUpload"
          #hiddenUpload
          (change)="setFile($event.target.files)"
          name="upload-start"
          accept="*/*"
        />
      </div>
    </div>
  </div>

  <div class="fd-accreditation-form__container__actions">
    <fd-button
      class="no-print"
      [content]="'Voltar para o simulador de taxas'"
      (trigger)="goBack()"
    >
    </fd-button>

    <fd-button
      class="no-print"
      [content]="'Criar Grupo'"
      (trigger)="createGroup()"
      [disabled]="validCreateGroup()"
    >
    </fd-button>
  </div>
</div>
