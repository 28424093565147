import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiResponse } from 'src/app/shared/models/response/base-api-response.model';
import { BaseConfigApiResponse } from 'src/app/shared/models/response/base-config-api-response.model';
import { environment } from 'src/environments/environment.local';
import { CnaeModel } from 'src/app/shared/models/response/response-cnae.model';
import { map, tap } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { RevenueRangeModel } from 'src/app/shared/models/response/response-revenue-range.model';
import { CampaignConfigModel, CampaignModel } from 'src/app/shared/models/response/response-campaing.model';
import { Constants } from 'src/app/order/constants/constants';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Injectable({
  providedIn: 'root',
})
export class CnaeService {

  agentChannel: string;

  constructor(private http: HttpClient,
    private dataStoreService: DataStoreService) {
      //this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData())
      this.agentChannel = '';
  }

  getCnaeList(filterValue: string): Observable<Item[]> {
    const filterQuery = filterValue ? `?partial-cnae=${filterValue}` : '';
    return this.http.get<BaseConfigApiResponse<CnaeModel[]>>(`${environment.apiUrls.cnaeSimulator}${filterQuery}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToCnaeModels(x.data)));
  }

  checkCnae(cnae: string, serviceContract: string) {
    const params = new HttpParams().set('cnae', cnae).set('serviceContract', serviceContract);
    return this.http.get<boolean>(`${environment.apiUrls.cnaeSimulator}/check`, {params});
  }

  getCampaignList(): Observable<Item[]> {
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this
      .http
      .get<CampaignModel[]>(`${environment.apiUrls.campaign}`, { headers })
      .pipe(map(x => this.invalidAPIResponse(x) ? null : this.mapToCampaignModels(x)));
  }

  getCampaignListWithCnpj(cnpj: string): Observable<Item[]> {
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this
      .http
      .get<CampaignModel[]>(`${environment.apiUrls.campaign}?cnpj=${cnpj}`,  { headers })
      .pipe(map(x => this.invalidAPIResponse(x) ? null : this.mapToCampaignModels(x)));
  }

  getRevenueRangeList(campaignId: string, cnaeNumber: string): Observable<Item[]> {
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this
      .http
      .get<RevenueRangeModel[]>(`${environment.apiUrls.revenueRange}/${campaignId}?cnae=${cnaeNumber}`, { headers })
      .pipe(tap(x => x.sort((a, b) => a.minAnnualBilling - b.minAnnualBilling)))
      .pipe(map(x => this.invalidAPIResponse(x) ? null : this.mapToRevenueRangeModels(x)));
  }

  getRevenueRangeListObject(campaignId: string, cnaeNumber: string): Observable<RevenueRangeModel[]> {
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this.http.get<RevenueRangeModel[]>(`${environment.apiUrls.revenueRange}/${campaignId}?cnae=${cnaeNumber}`, { headers });
  }

  public getCampaignListFromConfig(serviceContract: number): Observable<CampaignConfigModel[]> {
    return this.http
      .get<CampaignConfigModel[]>(`${environment.apiUrls.configCampaign}/service-contract/${serviceContract}`)
      .pipe(map((x) => (this.invalidAPIResponse(x) ? null : x)));
  }

  private mapToCampaignModels(response: CampaignModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.idCampaign,
      label: value.description
    }));
  }

  private mapToCnaeModels(response: CnaeModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.cnaeNumber,
      label: value.cnaeNumber.toString() + ' - ' + value.cnaeDescription,
      additionalProperties: {
        status: value.status,
        ecommerceExceptionCnae: value.ecommerceExceptionCnae
      }
    }));
  }

  private mapToRevenueRangeModels(response: RevenueRangeModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(value => ({
      value: value.idRange,
      label: `R$ ${this.convertToCurrency(value.minAnnualBilling)} - R$ ${this.convertToCurrency(value.maxAnnualBilling)}`,
    }));
  }

  private invalidResponse(baseApiResponse: BaseConfigApiResponse<CnaeModel[]>): boolean {
    return !baseApiResponse ||
      !!baseApiResponse.error ||
      !baseApiResponse.data;
  }
  private invalidAPIResponse(baseApiResponse: any): boolean {
    return !baseApiResponse;
  }


  private convertToCurrency(value: number) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
}
