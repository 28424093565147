<div class="fd-modal__container">
  <fa-icon icon="exclamation-triangle" class="fd-modal__warning-icon" size="3x"></fa-icon>
  <div mat-dialog-content class="fd-modal__title">
    <p>{{data.title}}</p>
  </div>
  <div mat-dialog-content  *ngIf="data.html" >
    <p [innerHtml] ="data.description"></p>
  </div>
  <div mat-dialog-content  *ngIf="!data.html" >
    <p>{{data.description}}</p>
  </div>
  <div mat-dialog-actions>
    <fd-button class="fd-modal__close-button" fdSecondary [content]="getButtonContent()" (trigger)="handleClick()">
    </fd-button>
  </div>
</div>
