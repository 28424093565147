import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.local";
import { DataStoreService } from "src/app/order/store/data-store.service";
import { EconomicalGroupModel } from './../../../components/simulation-data/eventual-economical-group/models/economical-group.model';
import { ValidCNPJResponseModel } from './../../../components/simulation-data/eventual-economical-group/models/valid-cnpj-response.model';

@Injectable({
  providedIn: "root",
})
export class EconomicGroupService {
  agentChannel: string;

  constructor(
    private http: HttpClient,
    private dataStoreService: DataStoreService
  ) {
    this.agentChannel = "";
  }

  checkGroup(cnpj: string): Observable<boolean> {
    const params = new HttpParams().set("cnpj", cnpj);
    return this.http.get<boolean>(
      `${environment.apiUrls.economicGroup}/check`,
      { params }
    );
  }

  getTemplate() {
    return this.http.get(
      `${environment.apiUrls.economicalGroupConvenience}/cnpjs/template`,
      { responseType: "blob" as "json" }
    );
  }

  saveMassive(formData: FormData) {
    return this.http.post(`${environment.apiUrls.economicalGroupConvenience}/save-massive`, formData);
  }

  saveUnit(economicalGroupCnpjs: EconomicalGroupModel) {
    return this.http.post(`${environment.apiUrls.economicalGroupConvenience}/save-unit`, economicalGroupCnpjs);
  }

  validUnit(cnpj: string): Observable<ValidCNPJResponseModel> {
    let params = new HttpParams();
    params = params.set('cnpj', cnpj);
    return this.http.get<ValidCNPJResponseModel>(`${environment.apiUrls.economicalGroupConvenience}/valid-unit`, {params});
  }
}
