import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  constructor(@Inject(DOCUMENT) private document) {}

  public scrollToFirstError(): void {
    this.scrollToElement('.fd-input__field--error');
  }

  public scrollAndFocusToFirstInvalid(): void {
    this.scrollToElement('.fd-field--invalid');
    this.focusToElement('.fd-field--invalid');
  }

  public scrollToTitle(): void {
    this.scrollToElement('.fd-content-card__orange-title');
  }

  public scrollToElement(querySelector: string): void {
    setTimeout(() => {
      const control = this.document.querySelector(querySelector);
      if (control) {
        control.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  public focusToElement(querySelector: string): void {
    setTimeout(() => {
      const control = this.document.querySelector(querySelector);
      if (control) {
        const field = control.querySelector('[class^="fd-"][class*="__field"]');
        if (field) {
          const input = field.querySelector('input');
          if (input) {
            input.focus();
          } else {
            field.focus();
          }
        }
      }
    });
  }
}
