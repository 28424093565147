<form *ngIf="!!formGroup && !!fields" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <fd-input [parentForm]="formGroup" [field]="fields.cpfCnpj"> </fd-input>
    </div>

    <div>
      <fd-select
        (loadItemsFn)="getCnaeList($event, false)"
        [parentForm]="formGroup"
        [field]="fields.cnaeNumber"
      >
      </fd-select>
    </div>

    <div>
      <fd-select [parentForm]="formGroup" [field]="fields.state"> </fd-select>
    </div>

    <div>
      <fd-currency-input [parentForm]="formGroup" [field]="fields.billingValue">
      </fd-currency-input>
    </div>

    <fd-button
      [disabled]="this.formGroup.invalid"
      content="consultar planos"
      (trigger)="showFeeData()"
    >
    </fd-button>
  </div>
</form>
