import { Component, OnInit, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent implements OnInit {

  @Output() buttonClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<InformationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: InformationModalDialogData,
              @Inject(DOCUMENT) private document: Document) { }

  async ngOnInit() {
  }

}

export interface InformationModalDialogData {
  description?: string;
  callbackFn?: () => any;
}

