import { Component, OnInit, ViewChild, ElementRef, Inject } from "@angular/core";
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { FileContentTypeEnum } from './../../../../enums/file-content-type.enum';
import { EconomicGroupService } from './../../../../services/external/economic-group/economic-group.service';
import { Messages } from 'src/app/order/messages/order.messages';
import { EconomicalGroupModelSearchResponse } from 'src/app/order/components/simulation-data/eventual-economical-group-search/models/economical-group-search.model';
import { FileService } from './../../../../services/internal/file/file.service';
import { ErrorService } from './../../../../services/internal/error/error.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';

@Component({
  selector: "app-edit-economical-group-conv",
  templateUrl: "./edit-economical-group-conv.component.html",
  styleUrls: ["./edit-economical-group-conv.component.scss"],
})
export class EditEconomicalGroupConvComponent implements OnInit {
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  data: FormData;
  selectedInstitution: string;
  fileName: string;

  @ViewChild("hiddenUpload", { static: false }) hiddenUpload: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private economicGroupService : EconomicGroupService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA)
    public dataEdit: EconomicalGroupModelSearchResponse
  ) {}

  ngOnInit() {
    this.createFormGroup();
    this.fields = this.createFields();
    this.data = new FormData();
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  openFileExplorer() {
    const el = this.hiddenUpload.nativeElement as HTMLInputElement;
    el.click();
  }

  save() {
    var dto = "";
    dto =
      '{ "id": ' +
      this.dataEdit.id +
      ', "name" : "' +
      this.formGroup.value.name +
      '",  "institution" :"' +
      Constants.CEF_INSTITUTION +
      '", "serviceContract" :' +
      Constants.CEF_SERVICE_CONTRACT +
      " }";
    this.data.append("dto", dto);
    this.economicGroupService
      .saveMassive(this.data)
      .pipe(finalize(() => this.data.delete("dto")))
      .subscribe(
        (response) => {
          this.dialogService.openDialog(Messages.ECONOMIC_GROUP_CONV_EDIT);
          this.close(response);
        },
        (err: HttpErrorResponse) =>{
          if(err?.error?.details?.invalidCNPJs){
            this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.closeErro(err.error.details.details), 'ok')
          } else {
            this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR)
          }
        }
      );
  }

  closeErro(details: string): void {
    this.fileService.saveFileFromBase64(details, FileContentTypeEnum.CSV, 'cnpj_participante.csv');
  }

  close(result = null): void {
    this.dialogRef.close(result);
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      name: [{ value: this.dataEdit.name, disabled: true}, [Validators.required]],
    });
  }

  createFields(): FdFieldConfigs {
    return {
      name: {
        label: "Nome do grupo econômico",
        controlName: "name",
        disabled: true,
        messages: {
          required: "Informe um nome",
        },
      },
    };
  }

  setFile(filesParams: FileList) {
    const newFileList = new Array<File>();
    const csvType = ".csv";
    const formData = new FormData();
    const length = filesParams.length;

    for (let i = 0; i < length; i++) {
      const file: File = filesParams[i];
      if (file.name.match(csvType)) {
        newFileList.push(file);
        this.fileName = file.name;
        var blob = new Blob([file], { type: "text/csv" });
        formData.append("file", blob, file.name);
      }
    }

    this.data = formData;
  }

  downloadTemplate() {
    // this.loadingService.show();
    this.economicGroupService
      .getTemplate()
      .pipe(finalize(() => { return }))
      .subscribe(
        (data) => {
          this.fileService.saveFile(
            data,
            FileContentTypeEnum.CSV,
            "cnpjs-template.csv"
          );
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleXHRError(err, Messages.REQUEST_ERROR);
        }
      );
  }
}
