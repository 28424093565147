<div *ngIf="offerQueryData">
  <div *ngIf="offerQueryData.phrases && offerQueryData.phrases.pricingTechnologyPhrase" class="cef-disclaimer no-margin-bottom">
    {{ offerQueryData.phrases.pricingTechnologyPhrase }}
  </div>
  <div *ngIf="offerQueryData.phrases && offerQueryData.phrases.phrase" class="cef-disclaimer">
    <span >{{ offerQueryData.phrases.phrase }}</span><br />
  </div>
  <div class="cef-disclaimer"  *ngIf="!isCampanhaPinpad()">
    Para soluções Link de Pagamento e  E-commerce poderá haver custos adicionais.<br />
    A solução POS no Celular será isenta de cobrança de aluguel. A qualquer momento, a Fiserv poderá retirar a isenção, e seu uso ficará sujeito a cobrança pela prestação do serviço contratado.
  </div>
  <div class="cef-disclaimer no-margin-top">
    <div *ngIf="!isCampanhaPinpad()">
      As condições comerciais exibidas nesta simulação referem-se ao item 6 da Solicitação de Credenciamento à 
      Azulzinha/FISERV, estabelecidas de acordo com o faturamento informado para o novo domicílio e o ramo de atividade do
      cliente, as quais estão sujeitas a aprovação da credenciadora. 
      <br />
      <br />
    </div>
    <div *ngIf="isCampanhaPinpad()">
    As condições comerciais exibidas nesta simulação referem-se ao item 6 da Solicitação de 
    Credenciamento à Azulzinha/FISERV, estabelecidas de acordo com o ramo de atividade 
    do cliente, as quais estão sujeitas a aprovação da credenciadora.
    <br />
    <br />
    </div>
    

    <div *ngIf="offerQueryData">
      {{ offerQueryData.getOfferMessage() }}
    </div>
    
    <br /><br />
    Preços válidos somente para o dia de hoje ({{ getCurrentDate() }})
  </div>

  <div class="cef-disclaimer">
    <div class="client-signature">
      Assinatura do cliente:<br />
      <div class="signature-line"></div>
    </div>
  </div>
</div>
