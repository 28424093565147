import { Item } from "src/app/shared/fd-form-components/fd-select/fd-select.component";

export class ParamRates {
  personType: string;
  cnae: string;
  annualBillingValue: Number;
  idCampaign: Number;
  campaignCode?: string;
  uf: string;
  receivingDeadline: Number;
  chargeModelEnum: string;
  cpfCnpj: string;
  automaticPrepayment: boolean;
  offerMessage?: string;
  hasEconomicGroup: boolean;
  institutionId: string;
  serviceContract: Number;
}

export class Phrases {
  phrase: string;
  pricingTechnologyPhrase: string;
}

export class CampaignSimulator extends Item {
  idCampaign: Number;
  campaignCode: string;
  description: string;
  hasMdrFlex: boolean;
  hasMdr: boolean;
  mdrWithPrepayment: boolean;
  minAnnualBilling: Number;
  maxAnnualBilling: Number;
  hasNegotiation: boolean;
}
