import { Component, OnInit } from '@angular/core';
import { DataStoreService } from 'src/app/order/store/data-store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private dataStorange: DataStoreService){
    
  }
  ngOnInit(): void {
    
  }

  public imgUrl = "/assets/images/azulzinha-logo.png";

  registrationCode(): string
  {
    let registrationCode = this.dataStorange.getRegisterUserCode();
    if(registrationCode=='null' || !registrationCode)
    {
      return '';
    }
    return registrationCode;
  }


}
