import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faArrowLeft,
  faBars,
  faCamera,
  faChevronDown,
  faExclamationTriangle,
  faInfoCircle,
  faPencilAlt,
  fas,
  faSearch,
  faSignOutAlt,
  faSortDown,
  faTools,
  faTrash,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {TextMaskModule} from 'angular2-text-mask';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';


import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxCurrencyModule} from 'ngx-currency';
import {
  FdRadioButtonComponent,
  FdRadioButtonConfig
} from './fd-form-components/fd-radio-button/fd-radio-button.component';
import {FdLabelComponent, FdLabelConfig} from './fd-form-components/fd-label/fd-label.component';
import {
  FdOptionPickerComponent,
  FdOptionPickerConfig
} from './fd-form-components/fd-option-picker/fd-option-picker.component';
import {
  FdValueEditorComponent,
  FdValueEditorConfig
} from './fd-form-components/fd-value-editor/fd-value-editor.component';
import {FdCheckboxComponent} from './fd-form-components/fd-checkbox/fd-checkbox.component';
import {FdTableComponent} from './fd-form-components/fd-table/fd-table.component';
import {
  FdCurrencyInputComponent,
  FdCurrencyInputConfig
} from './fd-form-components/fd-currency-input/fd-currency-input.component';
import {FdInputComponent, FdInputConfig} from './fd-form-components/fd-input/fd-input.component';
import {FdSelectComponent, FdSelectConfig} from './fd-form-components/fd-select/fd-select.component';
import {FdButtonComponent} from './fd-form-components/fd-button/fd-button.component';
import {HeaderComponent} from './header/header.component';
import {FdFeeEditorComponent, FdFeeEditorConfig} from './fd-form-components/fd-fee-editor/fd-fee-editor.component';
import {FdProposalCardComponent} from './fd-form-components/fd-proposal-card/fd-proposal-card.component';
import {FdSearchBarComponent} from './fd-form-components/fd-search-bar/fd-search-bar.component';
import {BrowserModule} from '@angular/platform-browser';
import {FdConfirmComponent} from './fd-form-components/fd-confirm/fd-confirm.component';
import {FdDatepickerComponent, FdDatepickerConfig} from './fd-form-components/fd-datepicker/fd-datepicker.component';
import {
  FdUnfilledProposalCardComponent
} from './fd-form-components/fd-unfilled-proposal-card/fd-unfilled-proposal-card.component';
import {FinishComponent} from '../order/components/simulation-data/finish/finish.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { VersionComponent } from './version/version.component';

export type FdFieldConfig =
  FdInputConfig |
  FdSelectConfig |
  FdRadioButtonConfig |
  FdLabelConfig |
  FdOptionPickerConfig |
  FdFeeEditorConfig |
  FdDatepickerConfig |
  FdCurrencyInputConfig |
  FdValueEditorConfig;

export interface FdFieldConfigs {
  [key: string]: FdFieldConfig;
}

@NgModule({
  declarations: [
    FdInputComponent,
    FdSelectComponent,
    FdButtonComponent,
    FdRadioButtonComponent,
    FdLabelComponent,
    FdCheckboxComponent,
    FdOptionPickerComponent,
    FdValueEditorComponent,
    FdTableComponent,
    FdCurrencyInputComponent,
    HeaderComponent,
    FdFeeEditorComponent,
    FdProposalCardComponent,
    FdSearchBarComponent,
    FdDatepickerComponent,
    FdConfirmComponent,
    FdUnfilledProposalCardComponent,
    FinishComponent,
    SideNavComponent,
    VersionComponent
  ],
  entryComponents: [FdConfirmComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatMenuModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatSelectInfiniteScrollModule,
    NgxCurrencyModule,
    MatToolbarModule,
    RouterModule,

  ],
  exports: [
    FdInputComponent,
    FdButtonComponent,
    FdSelectComponent,
    FdLabelComponent,
    FdRadioButtonComponent,
    FdCheckboxComponent,
    FdOptionPickerComponent,
    FdValueEditorComponent,
    FdTableComponent,
    FdCurrencyInputComponent,
    HeaderComponent,
    FdProposalCardComponent,
    FdFeeEditorComponent,
    FdSearchBarComponent,
    FdDatepickerComponent,
    FdConfirmComponent,
    SideNavComponent,
    VersionComponent
  ],
  providers: [
    MatDatepickerModule,
    MatTooltipModule,
    DatePipe
  ]
})

export class SharedComponentsModule {

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(faArrowLeft,
      faChevronDown,
      faCamera,
      faSearch,
      faUserCircle,
      faInfoCircle,
      faSortDown,
      faSignOutAlt,
      faBars,
      faExclamationTriangle,
      faPencilAlt,
      faTrash,
      faTools);
  }

}
