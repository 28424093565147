<form *ngIf="formGroup" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
      <h3 *ngIf="showTitle" class="subTitle-font"> {{ title }} </h3>
      <fd-radio-button
        (change)="setSelected($event)"
        [parentForm]="formGroup"
        [field]="fields.offerOptions"
      >
      </fd-radio-button>
    </div>
  </div>
</form>
