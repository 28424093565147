<table mat-table [dataSource]="dataSource" *ngIf="dataSource != null">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID </th>
    <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Nome </th>
    <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
  </ng-container>

  <ng-container matColumnDef="dateCreation">
    <th mat-header-cell *matHeaderCellDef>Data de criação </th>
    <td mat-cell *matCellDef="let element"> {{ element.dateCreation | date: 'dd/MM/yyyy' }} </td>
  </ng-container>

  <ng-container matColumnDef="dateLastMaintenance">
    <th mat-header-cell *matHeaderCellDef>Data da última manutenção </th>
    <td mat-cell *matCellDef="let element"> {{ element.dateLastMaintenance | date: 'dd/MM/yyyy' }} </td>
  </ng-container>

  <ng-container matColumnDef="btnDownload">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <fa-icon
        class="fd-icon"
        [ngClass]="{'fd-icon--disabled': isGroupDeleted(element.dateExclusion)}"
        [matTooltip]="isGroupDeleted(element.dateExclusion) ? '' : 'Download do arquivo de cnpjs'"
        icon="file-download"
        size="1x"
        (click)="onDownloadClick(element)">
      </fa-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="btnEdit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <fa-icon
        class="fd-icon"
        [ngClass]="{'fd-icon--disabled': isGroupDeleted(element.dateExclusion)}"
        [matTooltip]="isGroupDeleted(element.dateExclusion) ? '' : 'Editar grupo econômico'"
        icon="edit"
        size="1x"
        (click)="onEditClick(element)">
      </fa-icon>
    </td>
  </ng-container>

  <!-- Comentado aguardado definição CARD LBCO-61 -->
  <!-- <ng-container matColumnDef="btnDelete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <fa-icon
        class="fd-icon"
        [ngClass]="{'fd-icon--disabled': isGroupDeleted(element.dateExclusion)}"
        [matTooltip]="isGroupDeleted(element.dateExclusion) ? '' : 'Excluir grupo econômico'"
        icon="trash"
        size="1x"
        (click)="onDeleteClick(element)">
      </fa-icon>
    </td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
