<form *ngIf="!!formGroup && !!fields" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxLayoutGap="20px">
      <div fxFlex="50">
        <div>
          <fd-input
            [parentForm]="formGroup"
            [field]="fields.registrationNumber"
          >
          </fd-input>
        </div>
      </div>
      <div fxFlex="50">
        <div>
          <fd-input
            [parentForm]="formGroup"
            [field]="fields.agencyName"
            (input)="handleBlur($event)"
          >
          </fd-input>
        </div>
      </div>
    </div>
    <div>
      <fd-input
        [parentForm]="formGroup"
        [field]="fields.email"
        (input)="handleBlur($event)"
      >
      </fd-input>
    </div>
    <div>
      <fd-input
        [parentForm]="formGroup"
        [field]="fields.name"
        (input)="handleBlur($event)"
      >
      </fd-input>
    </div>
  </div>
</form>
