import { BaseFeeModel, BaseFeeValueModel } from './response-base-fee.model';
import { MdrFlexModalityEnum } from 'src/app/order/enums/mdr-flex-modality.enum';

export class MdrFlexFeeModel {
    codeFlag: number;
    flag: string;
    minimumMdrFlexFee: number;
    mdrFlexFeeEdit?: number;
    suggestedMdrFlexFee: number;
    serviceId: number;
    transaction: string;
    modality: MdrFlexModalityEnum;
    type: string;
    suggestedMdrFeeTariff: number;
    calculatedMdrFlexFee?: number;
}
