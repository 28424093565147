<button
  class="fd-button"
  [ngClass]="{
    'fd-button--primary': primary,
    'fd-button--secondary': secondary,
    'fd-button--tertiary': tertiary,
    'fd-button--disabled': disabled,
    'fd-button--thin': isThin,
    'fd-button--full-width': fullWidth,
    'fd-button--auto-width': autoWidth
  }"
  [disabled]="disabled || null"
  (click)="handleTrigger($event)">
  <div class="d-flex  justify-content-center" [innerHTML]="safeContent">
  </div>
</button>
