<ngx-spinner
  class="fd-spinner"
  bdColor="rgba(51,51,51,0.8)"
  size="default"
  color="#fff"
  type="ball-spin">
</ngx-spinner>
<app-header></app-header>
<router-outlet></router-outlet>
<app-version></app-version>
