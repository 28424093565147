import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NegociationDataModel } from 'src/app/order/components/simulation-data/fees-data/models/offer-query-data.model';
import { CampaignSimulator, ParamRates, Phrases } from 'src/app/order/components/simulation-data/fees-data/models/param';
import { Constants } from 'src/app/order/constants/constants';
import { MdrFlexModalityEnum } from 'src/app/order/enums/mdr-flex-modality.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { FeeCalculationModel } from 'src/app/shared/models/fee-calculation.model';
import { FactorFeeModel } from 'src/app/shared/models/response/response-factor-fee.model';
import { MdrFeeModel } from 'src/app/shared/models/response/response-mdr-fee.model';
import { MdrFlexFeeModel } from 'src/app/shared/models/response/response-mdr-flex-fee.model';
import { PrepaymentFeeModel } from 'src/app/shared/models/response/response-prepayment-fee.model';
import { environment } from 'src/environments/environment.local';
import { DialogService } from '../../internal/dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class FeeService {


  public sendNegociationMail(neg: NegociationDataModel): Observable<NegociationDataModel> {
    return this.http.post<NegociationDataModel>(`${environment.apiUrls.negociation}`, neg);
  }  

  getPhrases(param: ParamRates): Observable<Phrases> {
    return this.http.post<Phrases>(`${environment.apiUrls.labels}`, param);
  }
  
  getReport(param: ParamRates): Observable<any> {
    return this.http.post(`${environment.apiUrls.reportSimulator}`, param, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

  getCampaigns(param: ParamRates): Observable<CampaignSimulator[]> {
    return this.http.post<CampaignSimulator[]>(`${environment.apiUrls.campaigns}`, param);
  }  

  agentChannel: string;
  constructor(
    private http: HttpClient,
    private dataStoreService: DataStoreService,
    private dialogService: DialogService
  ) {
    if (this.dataStoreService.getUserData()) {
      this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData());
    }
    // this.agentChannel = '';
  }

  getMdrFees(param: ParamRates): Observable<MdrFeeModel[]> {
    //let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this
      .http
      .post<MdrFeeModel[]>(`${environment.apiUrls.feesSimulator}/mdr`, param)
      .pipe(
        take(1),
        tap((response) => {
          if (response && response.length) {
            return response;
          } else {
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FEES);
            return response;
          }
        }),
        catchError((err) => {
          const serializedError = err.error ? (err.error as ApiResultModel) : null;
          if (serializedError && serializedError.message) {
            this.dialogService.openDialogWithMessage(serializedError.message);
          } else {
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FEES);
          }
          return throwError(err);
        })
      );
  }

  getFactorFees(param: ParamRates): Observable<FactorFeeModel[]> {

    return this
      .http
      .post<FactorFeeModel[]>(
        `${environment.apiUrls.feesSimulator}/factor`, param
      ).pipe(
        take(1),
        tap((response: FactorFeeModel[]) => {
          if (response && response.length) {
            return response;
          } else {
            this.dialogService.openDialog(Messages.NO_FACTORS_AVAILABLE);
            return response;
          }
        }),
        catchError((err) => {
          const serializedError = err.error ? (err.error as ApiResultModel) : null;

          if (serializedError && serializedError.message) {
            this.dialogService.openDialogWithMessage(serializedError.message);
          } else {
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FACTORS);
          }
          return throwError(err);
        })
      )
  }

  getPrepaymentFees(param: ParamRates): Observable<PrepaymentFeeModel> {
    return this
      .http
      .post<PrepaymentFeeModel>(
        `${environment.apiUrls.feesSimulator}/prepayment`, param
      ).pipe(
        take(1),
        tap((response: PrepaymentFeeModel) => {
          if (response) {
            return response;
          } else {
            this.dialogService.openDialog(Messages.NO_FACTORS_AVAILABLE);
            return response;
          }
        }),
        catchError((err) => {
          const serializedError = err.error ? (err.error as ApiResultModel) : null;

          if (serializedError && serializedError.message) {
            this.dialogService.openDialogWithMessage(serializedError.message);
          } else {
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FACTORS);
          }
          return throwError(err);
        })
      )
  }

  getFactorFeesReceivingDeadLine(cnae: string, idRange: string, idCampaign: string): Observable<number[]> {
    return this
      .http
      .get<number[]>(`${environment.apiUrls.feesSimulator}/factor-receivingdeadline?cnae=${cnae}&id-range=${idRange}&id-campaign=${idCampaign}`);
  }

  getMdrFlexFees(param: ParamRates): Observable<MdrFlexFeeModel[]> {

    return this
      .http
      .post<MdrFlexFeeModel[]>(
        `${environment.apiUrls.feesSimulator}/mdrflex`, param
      ).pipe(
        take(1),
        tap((response) => {
          if (response && response.length) {
            return response;
          } else {
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES);
            return response;
          }
        }),
        catchError((err) => {
          const serializedError = err.error ? (err.error as ApiResultModel) : null;
          if (serializedError && serializedError.message) {
            this.dialogService.openDialogWithMessage(serializedError.message);
          } else {
            this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FLEX_FEES);
          }
          return throwError(err);
        })
      );
  }

  calculateFees(
    feeCalculation: FeeCalculationModel[],
    cnae: string,
    uf: string,
    idRange: string,
    idCampaign: string,
    receivingDeadline: number,
    personType: string): Observable<FeeCalculationModel[]> {
    feeCalculation = feeCalculation.filter((fee) => { return fee.modality != MdrFlexModalityEnum.ADDITIONAL_PER_INSTALLMENT }
    );
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this
      .http
      .post<FeeCalculationModel[]>(
        `${environment.apiUrls.feesSimulator}/calculate/mdrflex?uf=${uf}&cnae=${cnae}&id-range=${idRange}&id-campaign=${idCampaign}&person-type=${personType}&receiving-deadline=${receivingDeadline}`, feeCalculation, { headers });
  }

  getPrepaymentFee(
    cnae: string,
    idRange: string,
    idCampaign: string) {
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this
      .http
      .get<PrepaymentFeeModel>(
        `${environment.apiUrls.feesSimulator}/prepayment?cnae=${cnae}&id-range=${idRange}&id-campaign=${idCampaign}`, { headers });
  }

  errorHandler(err: HttpErrorResponse, message: Messages) {
    const serializedError = err.error ? (err.error as ApiResultModel) : null;
    if (serializedError && serializedError.message) {
      this.dialogService.openDialogWithMessage(serializedError.message);
    } else {
      this.dialogService.openDialog(message);
    }
    return throwError(err);
  }
}
