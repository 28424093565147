import { MdrModalityEnum } from 'src/app/order/enums/mdr-modality.enum';

export class MdrFeeModel {
    codeFlag: number;
    flag: string;
    minimumMdrFee: number;
    suggestedMdrFee: number;
    transaction: string;
    modality: MdrModalityEnum;
    feeEdit: number;
    serviceId:number;
    type: string;
    suggestedMdrFeeTariff: number;
}