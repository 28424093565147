import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseApiResponse, BaseApiResponseDetails } from 'src/app/shared/models/response/base-api-response.model';
import { Observable } from 'rxjs';
import { SimulationModel } from 'src/app/shared/models/simulation.model';
import { environment } from 'src/environments/environment.local';
import { map } from 'rxjs/operators';
import { ProposalStatusModel } from 'src/app/shared/models/response/response-proposal-status.model';
import { SubmittedProposalModel } from 'src/app/shared/models/response/response-submitted-proposal.model';
import { ProposalQueryModel } from 'src/app/shared/models/response/proposal-query.model';
import { ProposalStatusEnum } from 'src/app/order/enums/proposal-status.enum';
import { Pageable } from 'src/app/shared/models/pageable.model';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Constants } from 'src/app/order/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  agentChannel: string;

  constructor(private http: HttpClient,
    private dataStoreService: DataStoreService) {
      //this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData())
      this.agentChannel = '';

    }

  submitProposal(proposal: SimulationModel): Observable<SubmittedProposalModel> {
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this.http
      .post<BaseApiResponseDetails<SubmittedProposalModel>>(`${environment.apiUrls.proposal}`, proposal, {headers})
      .pipe(map(returnValue => returnValue ? returnValue.details : null));
  }

  findDuplicates(cpfCnpj: string, technologyGroup: string[]) {
    let params = new HttpParams();

    params = params.set('technologiesGroups', technologyGroup.toString());

    return this.http.get<boolean>(`${environment.apiUrls.proposal}/check-duplicity/${cpfCnpj}`, {params});
  }

  getRecentProposals(startDate: string, endDate: string, statusList?: ProposalStatusEnum[], size?: number, page?: number): Observable<Pageable<ProposalQueryModel[]>> {
    //this.agentChannel = this.dataStoreService.getUserDataAgentChannel(this.dataStoreService.getUserData())
    this.agentChannel = '';
    let isPendingPayment = false;

    if (statusList) {

      statusList.forEach((x, ind, arr) => {
        switch (x) {
          case ProposalStatusEnum.AWAITING_PAYMENT:
            isPendingPayment = true;
            statusList.splice(ind, 0);
            break;
        }
      })
    }

    let pageQuery = '';
    let sizeQuery = '';
    if(size){
      sizeQuery = `&size=${size}`;
    }
    if(page){
      pageQuery = `&page=${page}`;
    }

    const model = {
      statusList: statusList,
      isPendingPayment: isPendingPayment,
      startDate,
      endDate
    }

    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);

    return this.http.post<Pageable<ProposalQueryModel[]>>(`${environment.apiUrls.proposal}/submitted?1=1${pageQuery}${sizeQuery}`, model, {headers})
      .pipe(map(returnValue => returnValue))
  }

  getProposalStatus(proposalNumber: string): Observable<ProposalStatusModel> {
    let headers = new HttpHeaders().set(Constants.AGENT_ID_HEADER, this.agentChannel);
    return this.http.get<BaseApiResponseDetails<any>>(`${environment.apiUrls.proposal}/${proposalNumber}`, {headers})
      .pipe(map(returnValue => {
        if (this.invalidResponse(returnValue)) {
          return null;
        }
        else {
          if (returnValue.details) {
            return returnValue.details
          }
          else if (returnValue.datails) {
            return returnValue.datails;
          }
          else {
            return null;
          }
        }
      }))
  }

  private invalidResponse(baseApiResponse: BaseApiResponseDetails<ProposalStatusModel | Pageable<ProposalQueryModel[]>>): boolean {
    return !baseApiResponse ||
      !!baseApiResponse.error ||
      (!baseApiResponse.details && !baseApiResponse.datails);
  }
}
