import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinishComponent } from './order/components/simulation-data/finish/finish.component';
import { OrderStepGuard } from './order/guards/step-guard';
import { SimulationComponent } from './order/simulation.component';

const routes: Routes = [
  {
    path: 'simulation/client-information',
    component: SimulationComponent
  },
  {
    path: 'simulation/finish',
    component: FinishComponent
  },
  {
    path: 'negotiation/finish',
    component: FinishComponent
  },
  {
    path: '**',
    component: SimulationComponent,
    canActivate: [OrderStepGuard],
    pathMatch: 'full'
  },
  {
      path: 'economical-group-conv-search',
      component: FinishComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


