import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { OnlyNumberWithZeroMask } from 'src/app/order/masks/only-number-with-zero-mask';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { EmailMask } from 'src/app/order/masks/email-mask';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  formGroup: FormGroup = this.createFormGroup();
  fields: FdFieldConfigs = this.createFields();
  @Output() formGroupInfo = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private dataStorange: DataStoreService
  ) { }

  ngOnInit(): void {
  }

  handleBlur(event){
    this.formGroupInfo.emit(this.formGroup)
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      registrationNumber: [this.dataStorange.getRegisterUserCode(), [Validators.required]],
      agencyName: ['', [Validators.required, Validators.maxLength(4)]],
      email: ['', [Validators.required, Validators.maxLength(150), Validators.email]],
      name: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }

  protected createFields() {
    return {
      registrationNumber: {
        label: 'Matrícula',
        controlName: 'registrationNumber',
        isNumber: true,
        disabled: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe a matrícula',
          invalid: 'Matrícula inválido'
        },
      },
      agencyName: {
        label: 'Número da agência',
        controlName: 'agencyName',
        maxLength: 4,
        mask: OnlyNumberWithZeroMask,
        messages: {
          required: 'Informe o Número da agência',
        },
      },
      email: {
        label: 'E-mail',
        mask: EmailMask,
        maxLength: 150,
        controlName: 'email',
        messages: {
          required: 'Informe o email',
          pattern: 'E-mail inválido',
          email: 'E-mail inválido',
          invalid: 'E-mail inválido'
        },
      },
      name: {
        label: 'Nome do Grupo Econômico por Conveniência',
        controlName: 'name',
        maxLength: 100,
        messages: {
          required: 'Informe o nome do Grupo Econômico por Conveniência',
          invalid: 'Nome do Grupo Econômico por Conveniência inválido'
        }
      },
    };
  }

}
