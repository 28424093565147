<mat-form-field class="fd-input__container" color="accent">
  <div [formGroup]="parentForm" class="fd-input__field-container">
    <input matInput [formControlName]="field?.controlName" autocomplete="off" class="fd-input__field"
      [placeholder]="field?.label" [maxlength]="field?.maxLength" [textMask]="{
        mask: field?.mask || false,
        guide: true,
        placeholderChar: '\u2000'
      }" [id]="field?.controlName" [required]="required || null" aria-label="Area code" [type]="field?.type || 'text'"
      autocomplete="off" [attr.disabled]="field?.disabled || null" tabindex="0" (blur)="handleBlur($event)"
      (input)="handleChange($event)" />
  </div>
  <mat-icon matSuffix class="fd-input__icon-container" *ngIf="hasIcon" [innerHTML]="field?.iconHTML">
  </mat-icon>
  <fa-icon *ngIf="field?.tooltipMessage" matTooltipClass="fd-input__tooltip-dialog" class="fd-input__tooltip-icon"
    icon="info-circle" size="1x" mat-button [matTooltip]="field?.tooltipMessage"
    (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
    #tooltip="matTooltip" (click)="showTooltipAndStopPropagation($event)">
  </fa-icon>
  <mat-hint class="fd-input__hint">{{ field?.hint }}</mat-hint>
  <mat-error class="fd-input__error" *ngIf="hasError">
    <div *ngFor="let errorMessage of errorMessages">{{ errorMessage }}</div>
  </mat-error>
</mat-form-field>
