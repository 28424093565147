import { Component, OnInit, Input, Output, EventEmitter, Attribute, HostBinding } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export enum ButtonSize {
  NORMAL = 'normal',
  THIN = 'thin'
}
@Component({
  selector: 'fd-button',
  templateUrl: './fd-button.component.html',
  styleUrls: ['./fd-button.component.scss']
})
export class FdButtonComponent implements OnInit {

  fullWidth: boolean;
  autoWidth: boolean;
  primary: boolean;
  secondary: boolean;
  tertiary: boolean;

  @Input()
  content: string;

  @Input()
  size: ButtonSize = ButtonSize.NORMAL;

  get isThin(): boolean {
    return this.size === ButtonSize.THIN;
  }

  @Input()
  disabled: boolean;

  @Output() trigger = new EventEmitter<void>();

  safeContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer,
              @Attribute('fullWidth') fullWidth: string,
              @Attribute('autoWidth') autoWidth: string,
              @Attribute('fdSecondary') secondary: string,
              @Attribute('fdTertiary') tertiary: string) {

    this.fullWidth = fullWidth === '';
    this.autoWidth = autoWidth === '';
    this.secondary = secondary === '';
    this.tertiary = tertiary === '';
    this.primary = !this.secondary && !this.tertiary;
  }

  async ngOnInit() {
    this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  public handleTrigger(event: Event) {
    this.trigger.emit();
    event.stopPropagation();
    event.preventDefault();
  }

}
