import { MdrFeeModel } from "src/app/shared/models/response/response-mdr-fee.model";
import { MdrFlexFeeModel } from "src/app/shared/models/response/response-mdr-flex-fee.model";
import { Phrases } from "../../fees-data/models/param";

export class GenericMdrFeeModel {
    codeFlag: number;
    flag: string;
    serviceId: number;
    minimumMdrFee: number;
    suggestedMdrFee: number | string;
    transaction: string;
    modality: string;
    phrases: Phrases;
    type: string;
    suggestedMdrFeeTariff: number | string;

    public readonly TAX = 'TAX';
    public readonly CURRENCY = 'CURRENCY';

    constructor(mdrFeeModel: MdrFeeModel | MdrFlexFeeModel) {
        this.codeFlag = mdrFeeModel.codeFlag;
        this.flag = mdrFeeModel.flag;
        this.modality = mdrFeeModel.modality;
        this.serviceId = mdrFeeModel.serviceId;
        this.transaction = mdrFeeModel.transaction;
        this.minimumMdrFee = (mdrFeeModel as MdrFeeModel).minimumMdrFee || (mdrFeeModel as MdrFlexFeeModel).minimumMdrFlexFee;
        this.type = mdrFeeModel.type;
        
        if(this.isTax(mdrFeeModel.type)){
            this.suggestedMdrFeeTariff = "---"
            this.suggestedMdrFee = (mdrFeeModel as MdrFeeModel).suggestedMdrFee || (mdrFeeModel as MdrFlexFeeModel).suggestedMdrFlexFee;
            this.suggestedMdrFee = this.suggestedMdrFee == undefined? '--' : this.suggestedMdrFee;
        }else if(this.isCurrency(mdrFeeModel.type)){
            this.suggestedMdrFee =  "---"
            this.suggestedMdrFeeTariff = (mdrFeeModel as MdrFeeModel).suggestedMdrFee || (mdrFeeModel as MdrFlexFeeModel).suggestedMdrFlexFee;
        }
    }

    private isTax(type: string){
        return type === this.TAX;
    }

    private isCurrency(type: string){
        return type === this.CURRENCY;
    }

    public static arrayMap(mdrFeeModelsArray: any[]): GenericMdrFeeModel[] {
        if (mdrFeeModelsArray instanceof MdrFeeModel || MdrFlexFeeModel) {
           return mdrFeeModelsArray.map(mdrFeeModel => new GenericMdrFeeModel(mdrFeeModel));
        }
    }
}