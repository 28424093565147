<!-- <side-nav
    *ngIf="!createGroupForm && !negotiationForm && !progressiveDiscountTable"
    (menuClick)="openSideNav($event)"></side-nav> -->
<div class="fd-accreditation-form__container">
  <div *ngIf="!progressiveDiscountTable">
    <div *ngIf="!negotiationForm">
      <div class="forms" fxLayout="column" fxLayoutAlign="center stretch">
        <h4 class="fd-subtitle">SIMULADOR DE TAXAS</h4>
        <app-client-information
          *ngIf="viewControl.clientInformation"
          [clientInformation]="proposal.clientInformation"
          (consultOffers)="showFeesData($event)"
          (cleanDown)="clear()"
        ></app-client-information>

        <app-fees-data
          *ngIf="viewControl.feesData"
          [clientInformation]="proposal.clientInformation"
          [selectedCampaing]="offerQueryData.campaignId"
          (feeInfoEvent)="showOfferData($event)"
        >
        </app-fees-data>

      <app-simulation-table
        *ngIf="viewControl.advanceFeeTableData"
        [dataSource]="advanceFee$ | async"
        [objectProperties]="advanceFeeProperties"
        tableName="Taxa de antecipação automática">
      </app-simulation-table>

      <app-simulation-table
        *ngIf="viewControl.factorTableData"
        [dataSource]="factorFees$ | async"
        [objectProperties]="factorFeesProperties"
        tableName="{{viewControl.offerTableDataLotericaPinpad ? 'Serviço de Conveniência' : 'Fator'}}">
      </app-simulation-table>

      <div *ngIf="viewControl.factorTableData && !viewControl.offerTableDataLotericaPinpad" class="cef-disclaimer">
        O Fator 1 é a taxa cobrada no Crédito a vista para recebimentos em 2
        dias. O Fator 2 é a taxa adicional que será cobrada por cada parcela nas
        vendas a partir de 2 vezes.
      </div>

      <app-simulation-table
        *ngIf="viewControl.offerTableData && !viewControl.offerTableDataLotericaPinpad"
        [dataSource]="genericMdrFee$ | async"
        [objectProperties]="genericFeesProperties"
        tableName="Taxas MDR"
      >
      </app-simulation-table>

      <app-additional-information
        *ngIf="viewControl.offerTableData"
        [offerQueryData]="offerQueryData">
      </app-additional-information>

      <app-simulation-table
        *ngIf="viewControl.offerTableDataLoterica && !viewControl.offerTableDataLotericaPinpad"
        [dataSource]="genericMdrFee$ | async"
        [objectProperties]="feesPropertiesLotericas"
        tableName="Taxas MDR"
      >
      </app-simulation-table>

        <app-additional-information
          *ngIf="viewControl.offerTableDataLoterica"
          [offerQueryData]="offerQueryData"
        >
        </app-additional-information>
      </div>
    </div>

    <div *ngIf="negotiationForm && !createGroupForm">
      <app-negotiation-modal
        [clientInformation]="proposal.clientInformation"
        (backToMain)="closeNegotiationForm()"
      ></app-negotiation-modal>
    </div>

    <div *ngIf="createGroupForm">
      <app-eventual-economical-group
        (buttonGoBack)="goBack()"
      ></app-eventual-economical-group>
    </div>

    <!--<div *ngIf="createEconomicGroupSearch">
      <app-eventual-economical-group-search
      (buttonGoBack)="goBack()"
      ></app-eventual-economical-group-search>
    </div>-->

    <div
      *ngIf="
        !negotiationForm &&
        (viewControl.offerTableData || viewControl.offerTableDataLoterica)
      "
      class="fd-accreditation-form__container__actions"
    >
      <fd-button
        class="no-print"
        [content]="'imprimir'"
        *ngIf="offerQueryData"
        (trigger)="printOffer()"
      >
      </fd-button>

      <fd-button
        class="no-print"
        [content]="'Solicitar negociação'"
        (trigger)="openNegotiationForm()"
        *ngIf="
          offerQueryData &&
          !viewControl.offerTableDataLoterica &&
          offerQueryData.hasNegotiation
        "
      >
      </fd-button>

      <!-- <fd-button
        class="no-print"
        [content]="'Consultar Tabela de Desconto'"
        (trigger)="showProgressiveDiscount(true)"
        *ngIf="isGrouConvenience"
      >
      </fd-button> -->

      <fd-button
        class="no-print"
        [content]="'Finalizar Simulação'"
        (trigger)="finishSimulation()"
      >
      </fd-button>
    </div>
  </div>

  <!-- <app-progressive-discount
    *ngIf="progressiveDiscountTable"
    [institution]="CEF_INSTITUTION"
    [serviceContract]="CEF_SERVICE_CONTRACT"
    [customer]="progressiveDiscountTableCustomer"
    (close)="closeProgressiveDiscount($event)"
    (print)="printProgressiveDiscount($event)"
  >
  </app-progressive-discount> -->
</div>
