import { CnpjModel } from './cnpj.model';


export class EconomicalGroupModel {
  id?: number;
  institution?: string;
  serviceContract?: string;
  email: string;
  registration: string;
  name: string;
  cnpjs: Array<CnpjModel>;
}
