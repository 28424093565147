import { AbstractControl } from "@angular/forms";

export function EmailCompare(control: AbstractControl) {
  const email = control.get("email");
  const emailValue = email && email.value;

  const emailConfirmation = control.get("emailConfirmation");
  const emailConfirmationValue = emailConfirmation && emailConfirmation.value;

  if (
    !emailConfirmation ||
    !emailConfirmation.value ||
    !email ||
    !email.value
  ) {
    return {};
  }
  if (emailValue.toLowerCase() !== emailConfirmationValue.toLowerCase()) {
    return emailConfirmation.setErrors({ nomatch: true });
  } else {
    return emailConfirmation.setErrors(null);
  }
}

export const EmailRegExp = /^[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z0-9]{2,}$/i;
