import { Injectable } from '@angular/core';
import { Messages } from 'src/app/order/messages/order.messages';
import { MessageModalDialogData, MessageModalComponent } from 'src/app/order/components/modals/message-modal/message-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { InformationModalDialogData, InformationModalComponent } from 'src/app/order/components/modals/information-modal/information-modal.component';
import { LoadingService } from '../loading/loading.service';
import { ConfirmDialogData, FdConfirmComponent } from 'src/app/shared/fd-form-components/fd-confirm/fd-confirm.component';
import { ErrorModalComponent, ErrorModalDialogData } from 'src/app/order/components/modals/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog,
              private loadingService: LoadingService) { }

  public openConfirmDialog(message: Messages, confirmCallbackFn?: () => any, cancelCallbackFn?: () => any): void {

    const dialogData: ConfirmDialogData = {
      description: message.description,
      title: message.title,
      confirmCallbackFn,
      cancelCallbackFn
    };

    const dialogRef = this.dialog.open(FdConfirmComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  openDialog(message: Messages, callbackFn?: () => any, buttonText?: string, size: ModalDefinitions = ModalDefinitions.DEFAULT_MODAL_WIDTH): void {

    const dialogData: MessageModalDialogData = {
      description: message.description,
      title: message.title,
      buttonText,
      callbackFn
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      width: size,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  public openDialogWithMessage(message: string, callbackFn?: () => any, buttonText?: string): void {

    const dialogData: MessageModalDialogData = {
      description: message,
      title: 'Atenção',
      buttonText,
      callbackFn
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  public openErrorDialog(message: Messages, errorDetails: string, callbackFn?: () => any, buttonText?: string, alert?: string, size: ModalDefinitions = ModalDefinitions.DEFAULT_MODAL_WIDTH): void {

    const dialogData: ErrorModalDialogData = {
      description: message.description,
      title: message.title,
      errorDetails,
      buttonText,
      alert,
      callbackFn
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(ErrorModalComponent, {
      disableClose: true,
      width: size,
      data: dialogData
    });

    // Uncomment if any action is necessary after modal close.
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  public openInformationDialog(message: string): void {
    const dialogData: InformationModalDialogData = {
      description: message,
    };

    const dialogRef = this.dialog.open(InformationModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData
    });
  }

  public openDialogWithHtmlMessage(message: Messages, callbackFn?: () => any, buttonText?: string): void {
    const dialogData: MessageModalDialogData = {
      description: message.description,
      title: message.title,
      html: true,
      buttonText,
      callbackFn
    };

    if (callbackFn) {
      dialogData.callbackFn = callbackFn;
    }

    const dialogRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      data: dialogData,
    });
  }
}
