import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from 'src/app/order/constants/constants';
import { SessionInformation } from 'src/app/order/store/data-store-models';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { LoginModel } from 'src/app/shared/models/login.model';
import { TokenModel } from 'src/app/shared/models/response/token.model';
import { RoutingService } from '../routing/routing.service';
import { TokenService } from '../token/token.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _sessionInformation: SessionInformation;
  private tokenInformation$: BehaviorSubject<SessionInformation>;

  private _userData: LoginModel;
  private userData$: BehaviorSubject<LoginModel>;

  private loggedIn = false;

  constructor(
    private routingService: RoutingService,
    private dataStoreService: DataStoreService,
    private dialog: MatDialog,
    private tokenService: TokenService,
  ) {
    this._sessionInformation = new SessionInformation();
    this.tokenInformation$ = new BehaviorSubject(this._sessionInformation);

    this._userData = new LoginModel();
    this.userData$ = new BehaviorSubject(this._userData);
  }

  public getStorageData(): SessionInformation {
    const data = new SessionInformation();
    const strUser = this.dataStoreService.getUsername();
    const strToken = this.dataStoreService.getToken();

    data.name = strUser;
    data.sessionToken = strToken;

    try {
      if (
        data != null &&
        data.sessionToken.trim().length > 0 &&
        data.name.trim().length > 0
      ) {
        return data;
      }
    } catch {
      return null;
    }
  }

  public getAuthService(): Observable<SessionInformation> {
    return this.tokenInformation$;
  }

  public getSessionToken() {
    if (!this._sessionInformation.sessionToken && this.loggedIn) {
      this._sessionInformation.sessionToken = this.savedUserToken();
    }
    return this._sessionInformation.sessionToken;
  }

  public savedUserToken(): string {
    return sessionStorage.getItem(Constants.AUTH_SERVICE_TOKEN_KEY);
  }

  public savedUserName(): string {
    return sessionStorage.getItem(Constants.AUTH_SERVICE_NAME_KEY);
  }



  public isAuthServiceEmpty(authService: SessionInformation) {
    return (!authService || Object.keys(authService).length === 0);
  }

  public setAuthService(authService: TokenModel): boolean {
    console.log(authService);

    if (authService.fgBloqueado === 'N') {
      const decodedToken = this.tokenService.getDecodedAccessToken(authService.sessionToken);
      console.log(decodedToken);

      if (!decodedToken) {
        return false;
      }
      decodedToken.inf.hierarchyComplementaryInformation = authService.hierarchyComplementaryInformation;

      this.dataStoreService.setSessionInformation({ name: authService.nome, sessionToken: authService.sessionToken }, decodedToken, true);

      return true;
    }
    return false;
  }

  private clear() {
    this.dataStoreService.clearSessionInformation();

    this.loggedIn = false;
  }

  public logout() {
    this.clear();
    // this.routingService.navigateToLogin(true, false);
  }

  expiredToken() {
    this.logout();
  }

  isLoggedIn(): boolean {
    if (this.loggedIn) {
      return true;
    }

    this._sessionInformation = this.getStorageData();
    if (!this.checkToken(this._sessionInformation)) {
      this.clear();
      return false;
    }

    return true;
  }

  private checkToken(model: TokenModel | SessionInformation) {
    return model && model != null && model.sessionToken != null;
  }

  loginSuccess(loginData: TokenModel) {
    console.log(loginData);

    if (this.checkToken(loginData)) {
      this.loggedIn = this.setAuthService(loginData);
    } else {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

}
