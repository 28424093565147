import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';


@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {

  public actionType: 'SIMULAÇÃO' | 'NEGOCIAÇÃO';

  constructor(private router: Router) { }

  ngOnInit() {
    this.actionType = this.router.url === '/negotiation/finish' ? 'NEGOCIAÇÃO' : 'SIMULAÇÃO'
  }

}
