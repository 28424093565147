export class Constants {
  public static readonly ACCREDITATION_TOKEN_KEY = 'accreditationToken';
  public static readonly AUTH_SERVICE_TOKEN_KEY = 'authServiceToken';
  public static readonly AUTH_SERVICE_NAME_KEY = 'authServiceName';
  public static readonly ACCREDITATION_USERNAME_KEY = 'accreditationUsername';
  public static readonly ACCREDITATION_USER_DATA_KEY = 'accreditationUserData';
  public static readonly PROPOSAL_PRODUCTS_KEY = "proposalProducts";
  public static readonly PROPOSAL_NUMBER = "proposalDetailNumber"
  public static readonly CONFIGURATION_KEY = 'accreditationConfiguration'
  public static readonly ROUTE_STATUS_LOCALSTORAGE_KEY = "routeStatus"
  public static readonly PROPOSAL_LOCALSTORAGE_KEY = "proposal"
  public static readonly DROPDOWN_ITEMS_LOCALSTORAGE_KEY = "dropdownItems"
  public static readonly JUMP_STEPS_LOCALSTORAGE_KEY = "jumpSteps"
  public static readonly PROPOSAL_EXPIRATION_DATE_LOCALSTORAGE_KEY = "proposalExpirationDate"
  public static readonly PROPOSAL_FILTER_SEARCH_DATA_SESSIONSTORAGE_KEY = 'searchData';
  public static readonly SERPRO_SUCCESSFUL_DOCUMENTS_SESSIONSTORAGE_KEY = 'serproSuccessfulDocuments';
  public static readonly AGENT_ID_HEADER = 'agent-id';
  public static readonly PF_DOCUMENT_LENGTH = 11;
  public static readonly PJ_DOCUMENT_LENGTH = 14;
  public static readonly HIERARCHY_COMPLEMENTARY_INFORMATION = 'hierarchyComplementaryInformation';
  public static readonly ACTIVE_DRAFT_SESSIONSTORAGE_KEY = 'activeDraft';
  public static readonly IS_CEF_KEY = 'isCEF';


  public static readonly USER_DATA = 'registrationUserCode';
  public static readonly CURRENT_PROPOSAL = 'currentProposal';
  public static readonly CNAE = 'cnae';
  public static readonly UF = 'uf';
  public static readonly CPF_CNPJ = 'cpfCnpj';
  public static readonly MCC = 'mcc';

  public static readonly LOTTERY_CNAE = 8299706;
  public static readonly LOTTERY_CAMPANHA_CODE = 24;
  public static readonly LOTTERY_PINPAD_CAMPANHA_CODE = 51;

  public static readonly CEF_INSTITUTION = "00000007";
  public static readonly CEF_SERVICE_CONTRACT = 149;
}
