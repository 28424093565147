export function getValueWithDecimalPlaces(
  value: any,
  decimalPlaces: number = 2
) {
  if (value === null || value === undefined) {
    console.error("decimal-places: value not present");
    return;
  }
  if (isNaN(Number(value.toString().replace(",", ".")))) {
    console.error("decimal-places: value is not a number");
    return;
  }
  return Number(value.toString().replace(",", ".")).toLocaleString("pt-BR", {
    style: "decimal",
    minimumFractionDigits: decimalPlaces,
  });
}

export const getValueWithDecimalPlacesBrazillianFormat = (value: number) => {
  return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};
