import { EconomicalGroupModel } from './models/economical-group.model';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { Messages } from "src/app/order/messages/order.messages";
import { CnpjValidator } from "src/app/order/validators/cpf-cnpj-validator";
import { cnpjMask } from "src/app/order/masks/document-masks";
import { FdFieldConfigs } from "src/app/shared/shared-components.module";
import { CnpjModel } from './models/cnpj.model';
import { ValidCNPJResponseModel } from './models/valid-cnpj-response.model';
import { DialogService } from "./../../../services/internal/dialog/dialog.service";
import { FileService } from "./../../../services/internal/file/file.service";
import { ErrorService } from "./../../../services/internal/error/error.service";
import { FileContentTypeEnum } from "./../../../enums/file-content-type.enum";
import { EconomicGroupService } from "./../../../services/external/economic-group/economic-group.service";

@Component({
  selector: "app-eventual-economical-group",
  templateUrl: "./eventual-economical-group.component.html",
  styleUrls: ["./eventual-economical-group.component.scss"],
})
export class EventualEconomicalGroupComponent implements OnInit {
  public readonly CEF_SERVICE_CONTRACT = "149";
  public readonly CEF_INSTITUTION_FIRST_DATA = "00000007";

  data: FormData = new FormData();
  fileName: string = null;
  formGroupsCNPJ = new Array<FormGroup>();
  fields: FdFieldConfigs = this.createFields();
  validFormInfo: boolean = false;
  registrationNumber!: string;
  info: any;
  disableCNPJs: boolean = false;

  @ViewChild("hiddenUpload", { static: false }) hiddenUpload: ElementRef;
  @Output() buttonGoBack = new EventEmitter<any>();

  constructor(
    private economicGroupService: EconomicGroupService,
    private errorService: ErrorService,
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    let i = 0;
    while (i < 3) {
      this.formGroupsCNPJ.push(this.buildCNPJFormGroup());
      this.formGroupsCNPJ.forEach((formGroup) => {
       formGroup.get('delete').setValue(false);
      });
      i++;
    }

  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getBasicInformation(info: FormGroup) {
    this.validFormInfo = info.valid;
    this.registrationNumber = info.controls.registrationNumber.value;
    this.info = { ...info.value, registrationNumber: this.registrationNumber };
  }

  validCreateGroup(): boolean {
    return !this.validFormInfo || this.registrationNumber == null || (!this.validListCNPScheck() && this.fileName == null);
  }

  validListCNPScheck(): boolean {
    let list = this.formGroupsCNPJ.filter(formGroup => formGroup.value.cnpj != '')
    if(list.length > 0){
      return !list.some(formGroup => formGroup.value.check == false);
    } else {
      return false;
    }
  }

  goBack(): void {
    this.buttonGoBack.emit();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  createGroup() {
    if (this.validCreateGroup()) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR_GROUP);
      return;
    }
    if(this.fileName){
      let dto = "";
      dto =
        '{ "name" : "' +
        this.info.name +
        '",  "institution" : "' +
        this.CEF_INSTITUTION_FIRST_DATA +
        '",  "email" : "' +
        this.info.email +
        '", "serviceContract" :' +
        this.CEF_SERVICE_CONTRACT +
        "}";
      this.data.append("dto", dto);
      this.economicGroupService
        .saveMassive(this.data)
        .pipe(finalize(() => this.data.delete("dto")))
        .subscribe(
          () => {
            this.dialogService.openDialog(Messages.ECONOMIC_GROUP_CONV_SAVE, () => this.goBack());
          },
          (err: HttpErrorResponse) =>{
            if(err?.error?.details?.invalidCNPJs){
              this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close(err.error.details.details), 'ok')
            } else {
              this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR)
            }
          }
        );
    } else {
      let economicalGroupCnpjs: EconomicalGroupModel = new EconomicalGroupModel();
      let listCnpjs = this.buildCnpjModelArray(this.formGroupsCNPJ.filter(formGroup => formGroup.value.cnpj !== ''));
      economicalGroupCnpjs = {
        name: this.info.name,
        email: this.info.email,
        registration: this.info.registrationNumber,
        cnpjs: listCnpjs
      }

      this.economicGroupService
        .saveUnit(economicalGroupCnpjs)
        .pipe()
        .subscribe(
          () => {
            this.dialogService.openDialog(Messages.ECONOMIC_GROUP_CONV_SAVE, () => this.goBack());
          },
          (err: HttpErrorResponse) =>{
            if(err?.error?.details?.invalidCNPJs){
              this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close(err.error.details.details), 'ok')
            } else {
              this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR)
            }
          }
        );
    }
  }

  private buildCnpjModelArray(listCnpjsFormGroup: Array<FormGroup>): Array<CnpjModel> {
    return listCnpjsFormGroup.map(formGroup => this.convertCnpjFormGroupToModel(formGroup));
  }

  private convertCnpjFormGroupToModel(formGroup: FormGroup): CnpjModel {
    let cnpj = formGroup.value as CnpjModel;
    return cnpj;
  }

  close(details: string) {
    this.fileService.saveFileFromBase64(details, FileContentTypeEnum.CSV, 'cnpj_participante.csv');
  }

  handleInput(formGroup: FormGroup){
    formGroup.controls.check.setValue(false);
    if(formGroup.valid && formGroup.value.cnpj.length === 14){
      this.economicGroupService
        .validUnit(formGroup.value.cnpj)
        .pipe()
        .subscribe(
          (response: ValidCNPJResponseModel) => {
            formGroup.controls.check.setValue(response.data);
          },
          (err: HttpErrorResponse) =>{
            formGroup.controls.check.setValue(false);
          }
        );
    }


  }

  openFileExplorer() {
    const el = this.hiddenUpload.nativeElement as HTMLInputElement;
    el.click();
  }

  addNewCNPJ(): void {
    if (this.disableCNPJs) {
      return;
    }
    this.formGroupsCNPJ.push(this.buildCNPJFormGroup());
    var heightPage = document.body.scrollHeight;
    window.scrollTo({
      top: heightPage,
      left: 0,
      behavior: "smooth",
    });
  }

  delete(index: number): void {
    if (this.disableCNPJs) {
      return;
    }
    const list = this.formGroupsCNPJ[index].value;
    if (list.cnpj == null) {
      return;
    }
    this.formGroupsCNPJ = this.formGroupsCNPJ.filter((_, i) => i !== index);
  }

  validCNPJ(): boolean {
    return this.formGroupsCNPJ.findIndex((x) => x.value.cnpj != "") !== -1;
  }

  private buildCNPJFormGroup(): FormGroup {
    return this.formBuilder.group({
      cnpj: ["", CnpjValidator],
      check: [null],
      delete: [true]
    });
  }

  private createFields(): FdFieldConfigs {
    return {
      cnpj: {
        label: "CNPJ",
        maskCharsReplace: /[.\/ -]/g,
        mask: cnpjMask,
        controlName: "cnpj",
        messages: {
          required: "Informe CNPJ",
          invalidCnpj: "CNPJ inválido",
        },
      },
    };
  }

  setFile(filesParams: FileList) {
    const newFileList = new Array<File>();
    const csvType = ".csv";
    const formData = new FormData();
    const length = filesParams.length;

    for (let i = 0; i < length; i++) {
      const file: File = filesParams[i];
      if (file.name.match(csvType)) {
        newFileList.push(file);
        this.fileName = file.name;
        this.disableListCNPJ();
        var blob = new Blob([file], { type: "text/csv" });
        formData.append("file", blob, file.name);
      }
    }

    this.data = formData;
  }

  disableListCNPJ() {
    this.disableCNPJs = true;
    this.formGroupsCNPJ.map((formGroup) => formGroup.disable());
  }

  downloadTemplate() {
    if (!this.validCNPJ()) {
      this.economicGroupService
        .getTemplate()
        .pipe(
          finalize(() => {
            return;
          })
        )
        .subscribe(
          (data) => {
            this.fileService.saveFile(
              data,
              FileContentTypeEnum.CSV,
              "cnpjs-template.csv"
            );
          },
          (err: HttpErrorResponse) => {
            this.errorService.handleXHRError(err, Messages.REQUEST_ERROR);
          }
        );
    }
  }
}
