<div class="fd-modal__container">
    <fa-icon icon="exclamation-triangle" class="fd-modal__warning-icon" size="3x"></fa-icon>
    <div mat-dialog-content class="fd-modal__title">
        <p>{{data.title}}</p>
    </div>
    <div mat-dialog-content class="fd-modal__content">
        <p>{{data.description}}</p>
    </div>
    <div mat-dialog-actions>
        <fd-button class="fd-modal__close-button" [content]="'Confirmar'" (trigger)="handleConfirmClick()">
        </fd-button>
        <fd-button class="fd-modal__close-button" fdSecondary [content]="'Cancelar'" (trigger)="handleCancelClick()">
        </fd-button>
    </div>
</div>