/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ATENÇÃO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Não esquecer de equalizar todas as URL's nos demais arquivos de enviromment.
 *
 */
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import 'zone.js/dist/zone';
import * as ApiBaseUat from './api-base/api-base-uat-caixa';
export const apiBaseUat = ApiBaseUat.apiBase;
export const configApiBase = ApiBaseUat.configApiBase;
export const apiBase = `${ApiBaseUat.apiBase}/bwa`;
export const accreditationApi = `${apiBase}/credenciamento-caixa/online`;
export const environment = {
  firstOffer10k30k: {
    campaignId: 558,
    feeType: FeeTypeEnum.FLEX,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 120.000,01',
    billingRangeEnd: 'R$ 360.000,00',
    monthBillingStart: 'R$ 10.000,00',
    monthBillingEnd: 'R$ 30.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 59,00 / Pinpad Serial ou USB Aluguel: R$ 49,00 ',
    label: '',
    value: '',
  },
  secondOffer10k30k: {
    campaignId: 562,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 120.000,01',
    billingRangeEnd: 'R$ 360.000,00',
    monthBillingStart: 'R$ 10.000,00',
    monthBillingEnd: 'R$ 30.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 59,00 / Pinpad Serial ou USB Aluguel: R$ 49,00 ',
    label: '',
    value: '',
  },
  firstOffer30k85k: {
    campaignId: 559,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 360.000,01',
    billingRangeEnd: 'R$ 1.020.000,00',
    monthBillingStart: 'R$ 30.000,00',
    monthBillingEnd: 'R$ 85.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 49,00 / Pinpad Serial ou USB Aluguel: R$ 39,00',
    label: '',
    value: '',
  },
  secondOffer30k85k: {
    campaignId: 563,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 360.000,01',
    billingRangeEnd: 'R$ 1.020.000,00',
    monthBillingStart: 'R$ 30.000,00',
    monthBillingEnd: 'R$ 85.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 49,00 / Pinpad Serial ou USB Aluguel: R$ 39,00',
    label: '',
    value: '',
  },
  firstOffer85k250k: {
    campaignId: 560,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 1.020.000,01',
    billingRangeEnd: 'R$ 3.000.000,00',
    monthBillingStart: 'R$ 85.000,00',
    monthBillingEnd: 'R$ 250.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 39,00 / Pinpad Serial ou USB Aluguel: R$ 29,00',
    label: '',
    value: '',
  },
  secondOffer85k250k: {
    campaignId: 564,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 1.020.000,01',
    billingRangeEnd: 'R$ 3.000.000,00',
    monthBillingStart: 'R$ 85.000,00',
    monthBillingEnd: 'R$ 250.000,00',
    posPriceText: 'POS Convencional Aluguel: R$ 39,00 / Pinpad Serial ou USB Aluguel: R$ 29,00',
    label: '',
    value: '',
  },
  firstOfferPlus250k: {
    campaignId: 561,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: true,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  secondOfferPlus250k: {
    campaignId: 565,
    feeType: FeeTypeEnum.MDR,
    isPrepaymentEnabled: false,
    billingRangeStart: 'R$ 3.000.000,01',
    billingRangeEnd: 'R$ 999.999.999,99',
    monthBillingStart: 'R$ 250.000,00',
    monthBillingEnd: null,
    posPriceText: 'POS Convencional Aluguel: R$ 29,00 / Pinpad Serial ou USB Aluguel: R$ 19,00',
    label: '',
    value: '',
  },
  production: true,
  development: false,
  apiKey: 'IeBgvvjzmqQU47qXRyKwYX0ohFucum0H',
  saveSensitiveDataOnLocalStorage: false,
  ADMIN_URL: ApiBaseUat.adminUrl,
  apiUrls: {
    authentication: `${accreditationApi}/auth/login`,
    blackList: `${accreditationApi}/blacklist`,
    formConstitution: `${accreditationApi}/form-constitution`,
    validateClient: `${accreditationApi}/client-validator`,
    validateTechnologyGroup: `${accreditationApi}/client-validator/technology-validation`,
    serproPartnerValidation: `${accreditationApi}/serpro/validate-partner`,
    serproCpfValidation: `${accreditationApi}/serpro/cpf`,
    serproCnpjValidation: `${accreditationApi}/serpro/cnpj-partial`,
    serproCnpjInformation: `${accreditationApi}/serpro/cnpj-information`,
    validateMinimumAge: `${accreditationApi}/serpro/minimum-age-check`,
    mobileOperators: `${apiBase}/credenciamento/config/mobile-operator`,
    zipCode: `${accreditationApi}/zipcode`,
    cnae: `${accreditationApi}/cnae`,
    technologies: `${accreditationApi}/service/capture-solution`,
    bankList: `${apiBase}/credenciamento/config/bank`,
    bankValidate: `${accreditationApi}/bank`,
    proposal: `${accreditationApi}/proposal`,
    payment: `${accreditationApi}/purchase`,
    revenueRange: `${accreditationApi}/campaign/billing-range`,
    campaign: `${accreditationApi}/campaign`,
    rangeByTechnology: `${accreditationApi}/campaign/price-technology`,
    generalConfig: `${accreditationApi}/config-general`,
    registrationDataDetail: `${accreditationApi}/proposal/registration-data`,
    offerDataDetail: `${accreditationApi}/proposal/offer-data`,
    proposalDataDetail: `${accreditationApi}/proposal/proposal-data`,
    deliveryInformationDataDetail: `${accreditationApi}/proposal/delivery-information-data`,
    proposalNote: `${accreditationApi}/proposal/proposal-note`,
    validateSituation: `${accreditationApi}/serpro/situation-check`,
    terminalAmount: `${accreditationApi}/number-terminals`,
    terminalCheck: `${accreditationApi}/number-terminals/check-terminals`,
    apiStatus: `${accreditationApi}/api-status`,
    professionalLicenseStatus: `${accreditationApi}/professional-license`,
    validateByHierarchyChannel: `${accreditationApi}/client-validator/hierarchy-validation`,
    validateByRates: `${accreditationApi}/client-validator/rates-validation`,
    validadeCheckPrepaymentRate: `${accreditationApi}/campaign/check-has-prepayment-rate`,
    conciliator: `${accreditationApi}/conciliator`,
    proposalDraft: `${accreditationApi}/proposal-draft`,
    optIn: `${accreditationApi}/opt-in`,
    conciliatorCompany: `${accreditationApi}/conciliator-company`,
    simulation: `${accreditationApi}/simulator/simulation`,
    configCampaign: `${apiBase}/credenciamento/config/campaigns`,

    cnaeSimulator: `${accreditationApi}/simulator/cnae`,
    reportSimulator: `${accreditationApi}/simulator/report`,
    feesSimulator: `${accreditationApi}/simulator/rates`,
    labels: `${accreditationApi}/simulator/report-labels`,
    negociation: `${accreditationApi}/simulator/send-email`,
    decriptParams: `${accreditationApi}/simulator/decrypt-params`,
    decriptParam: `${accreditationApi}/simulator/decrypt-param`,
    campaigns: `${accreditationApi}/simulator/campaigns`,
    economicGroup: `${accreditationApi}/simulator/economic-group`,
    economicalGroupConvenience: `${accreditationApi}/simulator/economic-group-conv`,
    progressiveDiscount: `${accreditationApi}/simulator/progressive-discount`,
    reportProgressiveDiscount: `${accreditationApi}/simulator/progressive-discount/report`,
    reportCustomerProgressiveDiscount: `${accreditationApi}/simulator/customer-progressive-discount/report`
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone relEated error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 */
