import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OfferQueryDataModel } from '../fees-data/models/offer-query-data.model';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit {

  @Input() public offerQueryData: OfferQueryDataModel;

  constructor() { 
  }

  ngOnInit() {
  }

  public getCurrentDate() {
    return moment(new Date()).format('DD/MM/YYYY');
  }

  public isCampanhaPinpad(){
    return this.offerQueryData.campaignCode === 51;
  }

}
