import { EditEconomicalGroupConvComponent } from './edit-economical-group-conv/edit-economical-group-conv.component';
import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  EventEmitter,
  Output
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { HttpErrorResponse } from '@angular/common/http';

import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

import { FileContentTypeEnum } from './../../../enums/file-content-type.enum';
import { ErrorService } from './../../../services/internal/error/error.service';
import { FileService } from './../../../services/internal/file/file.service';
import { EconomicGroupConvenienceService } from "src/app/order/services/external/economic-group-convenience/economic-group-convenience.service";
import { EconomicalGroupModelSearchResponse } from "./models/economical-group-search.model";
import { Messages } from 'src/app/order/messages/order.messages';
import { DialogService } from "src/app/order/services/internal/dialog/dialog.service";

@Component({
  selector: 'app-eventual-economical-group-search',
  templateUrl: './eventual-economical-group-search.component.html',
  styleUrls: ['./eventual-economical-group-search.component.scss']
})
export class EventualEconomicalGroupSearchComponent implements OnInit {
  validFormInfo: boolean = false;
  @Output() buttonGoBack = new EventEmitter<any>();
  @Output() buttonSearch = new EventEmitter<any>();

  info: any;
  eventualEconomicalGroups$: Observable<EconomicalGroupModelSearchResponse[]>;


  constructor(
    private economicGroupConvenienceService : EconomicGroupConvenienceService,
    private dialogService: DialogService,
    private fileService: FileService,
    private errorService: ErrorService,
    private modal: MatDialog
  ) {}

  ngOnInit(): void {

  }
  getBasicInformation(info: FormGroup) {
    this.info = info;
  }

  goBack(): void {
    this.buttonGoBack.emit();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  public searchGroup(): void {
    if (!this.isFormValid()) {
      this.dialogService.openDialogWithMessage(Messages.INFORM_AT_LEAST_ONE_FIELD_ECONOMIC_GROUP_CONV_SEARCH.description);
      return;
    }

    const params = this.buildSearchGroupConvenienceParams();

    this.eventualEconomicalGroups$ = this.economicGroupConvenienceService
      .searchGroupConvenience(params)
      .pipe(
        map(res => [res]),
        catchError(e =>  {
          this.dialogService.openDialogWithMessage(Messages.ECONOMIC_GROUP_CONV_NOT_FOUND.description);
          return throwError(e);
        })
      );
  }

  private isFormValid(): boolean {
    return this.info != null && this.info.valid;
  }

  private buildSearchGroupConvenienceParams(): any {
    return {
      idGroup: this.info.controls.idGroup.value,
      cnpj: this.info.controls.cnpj.value,
      nameGroup: this.info.controls.name.value
    }
  }

  public deleteGroup(idGroup: number): void  {
    this.eventualEconomicalGroups$ = this.economicGroupConvenienceService
      .deleteGroupConvenience(idGroup)
      .pipe(
        map(res => [res]),
        catchError(e =>  {
          this.dialogService.openDialogWithMessage(Messages.ECONOMIC_GROUP_CONV_NOT_FOUND.description);
          return throwError(e);
        })
      );
  }

  public downloadCnpjs(group: EconomicalGroupModelSearchResponse): void  {
    this.economicGroupConvenienceService.downloadGroupConvenience(group.id).
    pipe().
    subscribe(
      (data) => {
        this.fileService.saveFile(data, FileContentTypeEnum.CSV, `cnpjs-${group.id}.csv`);
      },
      (err: HttpErrorResponse) => {this.errorService.handleXHRError(err, Messages.REQUEST_ERROR); });

  }

  public editGroup(group: EconomicalGroupModelSearchResponse): void  {
    const dialogRef = this.modal.open(EditEconomicalGroupConvComponent, {
      data: group
    });

    dialogRef.afterClosed().subscribe((response) => {
      if(response){
        this.searchGroup();
      }
    });
  }
}
