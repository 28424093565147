import { Component, OnInit, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltip, MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { DOCUMENT } from '@angular/common';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};
@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}
  ],
})

export class ErrorModalComponent implements OnInit {

  @Output() buttonClicked = new EventEmitter<any>();
  @ViewChild('tooltip', { static: true }) tooltip: MatTooltip;

  constructor(public dialogRef: MatDialogRef<ErrorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ErrorModalDialogData,
              @Inject(DOCUMENT) private document: Document) { }

  async ngOnInit() {
  }

  handleClick(): void {
    if (this.data.callbackFn) {
      this.data.callbackFn();
    }
    this.dialogRef.close();
  }

  getButtonContent() {
    return this.data && this.data.buttonText || 'fechar'
  }

  copyErrorToClipboard(error: string) {
    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData || window["clipboardData"];
      clipboard.setData("text", error);
      e.preventDefault();
    };

    document.addEventListener("copy", listener, false)
    document.execCommand("copy");
    document.removeEventListener("copy", listener, false);

    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.hide();
    }, 2000)
  }

}

export interface ErrorModalDialogData {
  title: string;
  description: string;
  errorDetails: string;
  buttonText: string;
  alert: string;
  callbackFn: () => any;
}


