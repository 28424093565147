import { EventualEconomicalGroupTableComponent } from './components/simulation-data/eventual-economical-group-search/eventual-economical-group-table/eventual-economical-group-table.component';
import { CommonModule, CurrencyPipe, PercentPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, OnDestroy } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faBars, faCamera, faInfoCircle, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { BackToHomeModalComponent } from './components/modals/back-to-home-modal/back-to-home-modal.component';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { FeeEditorModalComponent } from './components/modals/fee-editor-modal/fee-editor-modal.component';
import { InformationModalComponent } from './components/modals/information-modal/information-modal.component';
import { MessageModalComponent } from './components/modals/message-modal/message-modal.component';
import { OptionPickerModalComponent } from './components/modals/option-picker-modal/option-picker-modal.component';
import { ValueEditorModalComponent } from './components/modals/value-editor-modal/value-editor-modal.component';
import { AdditionalInformationComponent } from './components/simulation-data/additional-information/additional-information.component';
import { ClientInformationComponent } from './components/simulation-data/client-information/client-information.component';
import { FeesDataComponent } from './components/simulation-data/fees-data/fees-data.component';
import { NegotiationModalComponent } from './components/simulation-data/negotiation-modal/negotiation-modal.component';
import { SimulationTableComponent } from './components/simulation-data/simulation-table/simulation-table.component';
import { OrderStepGuard } from './guards/step-guard';
import { CnaeService } from './services/external/cnae/cnae.service';
import { LoadingService } from './services/internal/loading/loading.service';
import { ScrollService } from './services/internal/scroll/scroll.service';
import { SimulationComponent } from './simulation.component';
import { EventualEconomicalGroupComponent } from './components/simulation-data/eventual-economical-group/eventual-economical-group.component';
import { InformationComponent } from './components/simulation-data/eventual-economical-group/information/information.component';
import { ProgressiveDiscountComponent } from './components/simulation-data/progressive-discount/progressive-discount.component';
import { EventualEconomicalGroupSearchComponent } from './components/simulation-data/eventual-economical-group-search/eventual-economical-group-search.component';
import { InformationSearchComponent } from './components/simulation-data/eventual-economical-group-search/information-search/information-search.component';
import { EditEconomicalGroupConvComponent } from './components/simulation-data/eventual-economical-group-search/edit-economical-group-conv/edit-economical-group-conv.component';

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    SimulationComponent,
    MessageModalComponent,
    ErrorModalComponent,
    OptionPickerModalComponent,
    ValueEditorModalComponent,
    FeeEditorModalComponent,
    InformationModalComponent,
    BackToHomeModalComponent,
    ClientInformationComponent,
    FeesDataComponent,
    SimulationTableComponent,
    AdditionalInformationComponent,
    NegotiationModalComponent,
    EventualEconomicalGroupComponent,
    InformationComponent,
    ProgressiveDiscountComponent,
    EventualEconomicalGroupSearchComponent,
    InformationSearchComponent,
    EventualEconomicalGroupTableComponent,
    EditEconomicalGroupConvComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatGridListModule,
    FlexLayoutModule,
    SharedComponentsModule,
    FontAwesomeModule,
    MatSelectInfiniteScrollModule,
    NgxSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule
  ],
  providers: [
    OrderStepGuard,
    CnaeService,
    CurrencyPipe,
    PercentPipe,
    { provide: LOCALE_ID, useValue: 'pt-PT' }
  ],
  entryComponents: [
    MessageModalComponent,
    ErrorModalComponent,
    InformationModalComponent,
    BackToHomeModalComponent,
    OptionPickerModalComponent,
    FeeEditorModalComponent,
    ValueEditorModalComponent,
    NegotiationModalComponent
  ]
})
export class OrderModule implements OnDestroy {
  alive = true;

  constructor(private spinner: NgxSpinnerService,
              private loadingService: LoadingService,
              private scrollService: ScrollService) {
    library.add(faArrowLeft, faCamera, faPlusCircle, faTimes, faBars, faInfoCircle);
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
