<mat-toolbar class="header" color="primary">
  <div class="header__container">
    <img class="header__container__logo" [src]="imgUrl" />

    <div>
      <span class="header__container__label">Matrícula:</span>
      <input
        placeholder="Matrícula"
        class="inputStyle"
        [value]="registrationCode()"
        readonly
      />
    </div>
  </div>
</mat-toolbar>
