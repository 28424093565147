<form *ngIf="!!formGroup && !!fields" [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <div>
            <fd-input
              [parentForm]="formGroup"
              [field]="fields.idGroup"
              (input)="handleBlur($event)"
            >
            </fd-input>
          </div>
        </div>
        <div fxFlex="50">
          <div>
            <fd-input
              [parentForm]="formGroup"
              [field]="fields.cnpj"
              (input)="handleBlur($event)"
            >
            </fd-input>
          </div>
        </div>
      </div>
      <div>
        <fd-input
          [parentForm]="formGroup"
          [field]="fields.name"
          (input)="handleBlur($event)"
        >
        </fd-input>
      </div>
    </div>
  </form>
  