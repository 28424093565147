import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { EventualEconomicalGroupSearchComponent } from '../eventual-economical-group-search.component';
import { EconomicalGroupModelSearchResponse } from '../models/economical-group-search.model';

@Component({
  selector: 'app-eventual-economical-group-table',
  templateUrl: './eventual-economical-group-table.component.html',
  styleUrls: ['./eventual-economical-group-table.component.scss']
})
export class EventualEconomicalGroupTableComponent implements OnInit {

  @Input() dataSource: EconomicalGroupModelSearchResponse[];

  @Output() onDelete = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onDownload = new EventEmitter();

  displayedColumns: string[] = ['id', 'name', 'dateCreation', 'dateLastMaintenance', 'dateExclusion', 'btnDownload', 'btnEdit'];

  constructor(
    private eventualEconomicGroupSearch : EventualEconomicalGroupSearchComponent
  ) { }

  ngOnInit() {
  }

  public isGroupDeleted(dateExclusion: string): boolean {
    return dateExclusion != null;
  }

  public onDeleteClick(group: EconomicalGroupModelSearchResponse): void {
      return this.eventualEconomicGroupSearch.deleteGroup(group.id);
  }

  public onEditClick(group: EconomicalGroupModelSearchResponse): void {
    if (this.isGroupDeleted(group.dateExclusion)) {
      return;
    }

    this.onEdit.emit(group);
  }

  public onDownloadClick(group: EconomicalGroupModelSearchResponse): void {
    if (this.isGroupDeleted(group.dateExclusion)) {
      return;
    }

    this.onDownload.emit(group);
  }
}
