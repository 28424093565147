import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimulationModel } from 'src/app/shared/models/simulation.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  constructor(private http: HttpClient) {}

  saveSimulation(model: SimulationModel){
    return this.http.post<SimulationModel>(`${environment.apiUrls.simulation}`, model)
  }

  decriptParam(param: string): Observable<any>
  {
    param = param.replace(/\//g, "_");
    return this.http.get<any>(`${environment.apiUrls.decriptParam}/${param}`)
  }

}
