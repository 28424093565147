import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { cnpjMask } from 'src/app/order/masks/document-masks';
import { OnlyNumberMask } from 'src/app/order/masks/only-number-mask';
import { CnpjValidator } from 'src/app/order/validators/cpf-cnpj-validator';

@Component({
  selector: 'app-information-search',
  templateUrl: './information-search.component.html',
  styleUrls: ['./information-search.component.scss']
})
export class InformationSearchComponent implements OnInit {

  formGroup: FormGroup = this.createFormGroup();
  fields: FdFieldConfigs = this.createFields();
  @Output() formGroupInfo = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  handleBlur(event){
    this.formGroupInfo.emit(this.formGroup)
    this.disableFields(this.formGroup);
    if(this.formGroup.controls.idGroup.value == '' && this.formGroup.controls.name.value == ''
      && this.formGroup.controls.cnpj.value == ''){
        this.enableFields(this.formGroup);
    }
  }

  enableFields(FormGroup){
    this.formGroup.controls.name.enable();
    this.formGroup.controls.idGroup.enable();
    this.formGroup.controls.cnpj.enable();
  }

  disableFields(FormGroup){
    if(this.formGroup.controls.idGroup.value != ''){
      this.formGroup.controls.name.disable();
      this.formGroup.controls.cnpj.disable();
    }
    if(this.formGroup.controls.name.value != ''){
      this.formGroup.controls.idGroup.disable();
      this.formGroup.controls.cnpj.disable();
    }
    if(this.formGroup.controls.cnpj.value != ''){
      this.formGroup.controls.name.disable();
      this.formGroup.controls.idGroup.disable();
    }
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      idGroup: ['', [Validators.required]],
      cnpj: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }

  protected createFields() {
    return {
      idGroup: {
        label: 'ID do Grupo',
        controlName: 'idGroup',
        mask: OnlyNumberMask,
        messages: {
          invalid: 'Matrícula inválido'
        },
      },
      cnpj: {
        label: 'CNPJ participante do Grupo',
        controlName: 'cnpj',
        mask: cnpjMask,
        messages: {
          invalid: 'CNPJ inválido',
        },
      },
      name: {
        label: 'Nome do Grupo',
        maxLength: 150,
        controlName: 'name',
        messages: {
          invalid: 'Nome inválido'
        },
      },
   };
  }

}
