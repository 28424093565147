<div class="fd-modal__container">
  <fa-icon *ngIf="data.alert !== 'error'" icon="exclamation-triangle" class="fd-modal__warning-icon" size="3x">
  </fa-icon>
  <fa-icon *ngIf="data.alert === 'error'" icon="tools" class="fd-modal__unavailable-icon" size="3x"></fa-icon>
  <div mat-dialog-content class="fd-modal__title">
    <p>{{data.title}}</p>
  </div>
  <div mat-dialog-content>
    <p>{{data.description}}</p>
  </div>

  <mat-accordion class="mb-4">
    <mat-expansion-panel (opened)="copyErrorToClipboard(data.errorDetails)" #tooltip="matTooltip"
      (mouseenter)="$event.stopImmediatePropagation()"
      (mouseleave)="$event.stopImmediatePropagation()"
      matTooltip="Detalhes copiados para a área de transferência" matTooltipPosition="below">
      <mat-expansion-panel-header>
        <mat-panel-description>
          Detalhes do erro
        </mat-panel-description>
      </mat-expansion-panel-header>
      <pre
        class="fd-modal__details-container"><code class="fd-modal__details" style="">{{data.errorDetails}}</code></pre>


    </mat-expansion-panel>
  </mat-accordion>
  <div mat-dialog-actions>
    <fd-button class="fd-modal__close-button" fdSecondary [content]="getButtonContent()" (trigger)="handleClick()">
    </fd-button>
  </div>
</div>
