import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';

// Intercepta a requisição e gerencia a fila de carregamento.
@Injectable({ providedIn: 'root' })
export class FlightRequest implements HttpInterceptor {
  constructor(private loadingService: LoadingService, private router: Router) {
    this.routeLoading();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      tap(() => {
        this.loadingService.adicionaLoadingEvent(request.url);
      }),
      finalize(() => {
        this.loadingService.removeLoadingEvent(request.url);
      })
    );
  }

  private routeLoading() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.loadingService.adicionaLoadingEvent('navigation');
      }

      if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        this.loadingService.removeLoadingEvent('navigation');
      }
    });
  }
}
