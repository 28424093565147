export enum ProposalStatusEnum {
  IN_ANALYSIS = 'IN ANALYSIS',
  IN_CREDIT_MANUAL_ANALYSIS = 'IN CREDIT MANUAL ANALYSIS',
  IN_COMPLIANCE_MANUAL_ANALYSIS = 'IN COMPLIANCE MANUAL ANALYSIS',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  AWAITING_PAYMENT = 'AWAITING PAYMENT',
  IN_PRICE_MANUAL_ANALYSIS = "IN PRICE MANUAL ANALYSIS",
  SERPRO_UNAVAILABLE = "IN ANALYSIS SERPRO UNAVALIABLE",
  DENIED_INTEGRATOR = 'DENIED - INTEGRATOR',

  //Status existente somente no front-end. Nâo existe no banco
  NOT_FINISHED = 'NOT FINISHED'

}
