<h4 class="fd-subtitle">ANÁLISE DA NEGOCIAÇÃO</h4>
<div>
  <div fxLayoutGap="20px">
    <div fxFlex="50">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.registrationNumber">
        </fd-input>
      </div>
    </div>
    <div fxFlex="50">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.agencyName">
        </fd-input>
      </div>
    </div>
  </div>
  <!-- <div>
    <fd-input [parentForm]="formGroup" [field]="fields.email"> </fd-input>
  </div> -->
  <div>
    <fd-input [parentForm]="formGroup" [field]="fields.cnpj"> </fd-input>
  </div>
  <div>
    <!-- <fd-input [parentForm]="formGroup" [field]="fields.formCnae">
  </fd-input> -->

    <div>
      <fd-select
        (loadItemsFn)="getCnaeListForNeg($event)"
        [parentForm]="formGroup"
        [field]="fields.formCnae"
      >
      </fd-select>
    </div>
  </div>

  <div>
    <fd-input [parentForm]="formGroup" [field]="fields.socialReason">
    </fd-input>
  </div>

  <div>
    <fd-currency-input [parentForm]="formGroup" [field]="fields.annualRevenue">
    </fd-currency-input>
  </div>

  <h4 class="fd-subtitle">Deseja antecipar?</h4>
  <div>
    <fd-radio-button
      [parentForm]="formGroup"
      [field]="fields.prepaymentEnabled"
    >
    </fd-radio-button>
  </div>

  <div fxLayoutGap="20px">
    <div fxFlex="33">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.posAmount">
        </fd-input>
      </div>
    </div>

    <div fxFlex="33">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.terminalUnitPrice"
        >
        </fd-currency-input>
      </div>
    </div>

    <div fxFlex="33">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.requestedExceptions">
        </fd-input>
      </div>
    </div>
  </div>
  <h4>Distribuição do faturamento por Produto (%)</h4>
  <h5 style="color: gray; margin-top: -15px;">Informe o percentual que o cliente fatura mensalmente</h5>
  <div fxLayoutGap="20px">
    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.revenuePercentCredit"
        >
        </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.revenuePercentDebit"
        >
        </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.revenuePercent2to6"
        >
        </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.revenuePercent7to12"
        >
        </fd-currency-input>
      </div>
    </div>
  </div>

  <h4>Taxas Sugeridas</h4>
  <h5 style="color: gray; margin-top: -15px;">Informe as taxas que o cliente possui na concorrência</h5>
  <h4>Visa e Master</h4>
  <div fxLayoutGap="20px">
    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.suggestedFeesCredit"
        >
        </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.suggestedFeesDebit"
        >
        </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.suggestedFees2to6"
        >
        </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input
          [parentForm]="formGroup"
          [field]="fields.suggestedFees7to12"
        >
        </fd-currency-input>
      </div>
    </div>
  </div>

  <h4>Elo e Demais bandeiras</h4>
  <div fxLayoutGap="20px">
    <div fxFlex="25">
      <div>
        <fd-currency-input [parentForm]="formGroup" [field]="fields.suggestedEloOthersFeesCredit"> </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input [parentForm]="formGroup" [field]="fields.suggestedEloOthersFeesDebit"> </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input [parentForm]="formGroup" [field]="fields.suggestedEloOthersFees2to6"> </fd-currency-input>
      </div>
    </div>

    <div fxFlex="25">
      <div>
        <fd-currency-input [parentForm]="formGroup" [field]="fields.suggestedEloOthersFees7to12"> </fd-currency-input>
      </div>
    </div>
  </div>

  <div class="fd-accreditation-form__container__actions">
    <fd-button
      class="no-print"
      content="Voltar para o Simulador de taxas"
      (trigger)="backToOrigin()"
    > </fd-button>

    <fd-button
      class="no-print"
      [content]="'Enviar análise da Negociação'"
      (trigger)="sendNegotiation()"
      [disabled]="formIsInvalid"
    >
    </fd-button>
  </div>
</div>
