import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.local';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { Observable } from 'rxjs';
import { EconomicalGroupModelSearchResponse } from 'src/app/order/components/simulation-data/eventual-economical-group-search/models/economical-group-search.model';

@Injectable({
  providedIn: 'root',
})
export class EconomicGroupConvenienceService {

  constructor(private http: HttpClient,
              private dataStoreService: DataStoreService) {
  }

  checkGroupConvenience(cnpj: string, serviceContract: string): Observable<boolean> {
    const params = new HttpParams().set('cnpj', cnpj).set('serviceContract', serviceContract);
    return this.http.get<boolean>(`${environment.apiUrls.economicalGroupConvenience}/check`, {params});
  }

  searchGroupConvenience(params: any) {
    const urlParams = new HttpParams().set('idGroup', params.idGroup).set('cnpj', params.cnpj).set('name', params.nameGroup);
    return this.http.get<EconomicalGroupModelSearchResponse>(`${environment.apiUrls.economicalGroupConvenience}/search-group`, {params: urlParams});
  }

  deleteGroupConvenience(idGroup: number) {
    const params = new HttpParams().set('idGroup', idGroup);
    return this.http.get<EconomicalGroupModelSearchResponse>(`${environment.apiUrls.economicalGroupConvenience}/delete-group`, {params: params});
  }

  downloadGroupConvenience(idGroup: number) {
    return this.http.get<any>(`${environment.apiUrls.economicalGroupConvenience}/cnpjs/${idGroup}`, { responseType: 'blob' as 'json' });
  }

}
