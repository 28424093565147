import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompareTypeEnum } from 'src/app/order/enums/compare-type.enum';
import { cnpjMask } from 'src/app/order/masks/document-masks';
import { OnlyNumberMask } from 'src/app/order/masks/only-number-mask';
import { OnlyNumberWithZeroMask } from 'src/app/order/masks/only-number-with-zero-mask';
import { Messages } from 'src/app/order/messages/order.messages';
import { CnaeService } from 'src/app/order/services/external/cnae/cnae.service';
import { FeeService } from 'src/app/order/services/external/fee/fee.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { CompareValidatorNumberConvert, EqualsSumValidator } from 'src/app/order/validators/compare-validator';
import { CnpjValidator } from 'src/app/order/validators/cpf-cnpj-validator';
import { EmailRegExp } from 'src/app/order/validators/email-compare-validator';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { ClientInformationModel } from 'src/app/shared/models/simulation.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { NegociationDataModel } from '../fees-data/models/offer-query-data.model';

@Component({
  selector: 'app-negotiation-modal',
  templateUrl: './negotiation-modal.component.html',
  styleUrls: ['./negotiation-modal.component.scss']
})
export class NegotiationModalComponent implements OnInit {


  @Input() public clientInformation: ClientInformationModel;
  @Output() public backToMain = new EventEmitter<any>();

  public yesNoItems: Item[] = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
  ];

  public fields = this.createFields();
  public formGroup = this.createFormGroup();
  public isCnaeLoaded: Subject<boolean> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private cnaeService: CnaeService,
    private feeService: FeeService,
    private dialogService: DialogService,
    private router: Router,
    private dataService: DataStoreService
      ) { }

  ngOnInit() {
    this.appendValidators();
    this.formControls.cnpj.setValue(this.clientInformation.cpfCnpj);
    this.formControls.registrationNumber.setValue(this.dataService.getRegisterUserCode());
    let cnaeParam = this.clientInformation.cnaeNumber;
    this.getCnaeListForNeg(cnaeParam.toString());
    this.formControls.annualRevenue.setValue(this.clientInformation.billingValue);
    this.setEmail();
  }

  get cnaeDropdownNeg() {
    return this.fields.formCnae as FdSelectConfig;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public getCnaeListForNeg(filterValue: string) {
    if (!filterValue || !filterValue.length) {
      return;
    }

    if (filterValue.length < 3) {
      this.cnaeDropdownNeg.items = [];
      return;
    }

    this.cnaeService.getCnaeList(filterValue)
      .pipe(take(1))
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          this.cnaeDropdownNeg.items = [];
          this.cnaeDropdownNeg.items.push(...cnaeList);
          this.isCnaeLoaded.next(true);
          let currentItem = this.cnaeDropdownNeg.items.find((x) => x.value == filterValue);
          if (currentItem) {
            currentItem.selected = true;
            this.formControls.formCnae.setValue(currentItem.value);
          }
        }
      }, () => {
        this.cnaeDropdownNeg.items = [];
      });
  }

  sendNegotiation() {

    const neg = this.getNegotiationFields();
    if (this.isFormGroupValid(this.formGroup) && this.validateNegociationForm(neg)) {
      this.feeService.sendNegociationMail(neg).subscribe(
        (resp) => {
          this.dialogService.openDialogWithHtmlMessage(Messages.SOLICITATION_SUCCESS, () => this.router.navigateByUrl('/negotiation/finish'));
        },
        (erro) => {
          this.dialogService.openDialog(Messages.SIMULATION_SEND_ERROR);
          console.error(erro);
        }
      );
    }

  }

  backToOrigin(){
    this.backToMain.emit();
  }

  isFormGroupValid(formGroup: FormGroup): boolean {
    return formGroup.valid;
  }

  private validateNegociationForm(neg: NegociationDataModel): boolean {
    if (Number(neg.requestedExceptions) > neg.posAmount) {
      this.dialogService.openDialog(Messages.TOTAL_REQUESTED_EXCEPTIONS);
      return false;
    }

    let value = neg.revenuePercent2to6 + neg.revenuePercent7to12 + neg.revenuePercentCredit + neg.revenuePercentDebit;
    if(value!=100)
    {
      this.dialogService.openDialog(Messages.TOTAL_REVENUE_PERCENT);
      return false;
    }

    return true;
  }

  private setEmail(): void {
    const emailSuffix = 'caixa.onmicrosoft.com';
    const registrationNumber = this.formControls.registrationNumber.value;

    if (this.registrationNumberIsEmpty) {
      this.dialogService.openDialog(Messages.EMAIL_INVALID);
      return;
    }

    const email = `${registrationNumber}@${emailSuffix}`;

    this.formControls.email.setValue(email);
  }

  private getNegotiationFields(): NegociationDataModel {
    const neg = new NegociationDataModel();
    neg.registrationNumber = this.formControls.registrationNumber.value;
    neg.requestedExceptions = this.formControls.requestedExceptions.value;
    neg.revenuePercent2to6 = this.formControls.revenuePercent2to6.value;
    neg.revenuePercent7to12 = this.formControls.revenuePercent7to12.value;
    neg.revenuePercentCredit = this.formControls.revenuePercentCredit.value;
    neg.revenuePercentDebit = this.formControls.revenuePercentDebit.value;
    neg.socialReason = this.formControls.socialReason.value;
    neg.suggestedFees2to6 = this.formControls.suggestedFees2to6.value;
    neg.suggestedFees7to12 = this.formControls.suggestedFees7to12.value;
    neg.suggestedFeesCredit = this.formControls.suggestedFeesCredit.value;
    neg.suggestedFeesDebit = this.formControls.suggestedFeesDebit.value;
    neg.terminalUnitPrice = this.formControls.terminalUnitPrice.value;

    neg.annualRevenue = this.formControls.annualRevenue.value;
    neg.cnpj = this.formControls.cnpj.value;
    neg.email = this.formControls.email.value;
    neg.agencyName = this.formControls.agencyName.value;
    neg.posAmount = this.formControls.posAmount.value;
    neg.formCnae = this.formControls.formCnae.value;
    neg.prepaymentEnabled = this.formControls.prepaymentEnabled.value;

    neg.suggestedEloOthersFees2to6 = this.formControls.suggestedEloOthersFees2to6.value;
    neg.suggestedEloOthersFees7to12 = this.formControls.suggestedEloOthersFees7to12.value;
    neg.suggestedEloOthersFeesCredit = this.formControls.suggestedEloOthersFeesCredit.value;
    neg.suggestedEloOthersFeesDebit = this.formControls.suggestedEloOthersFeesDebit.value;

    return neg;
  }


  createFields(): FdFieldConfigs {
    return {
      registrationNumber: {
        label: 'Matrícula',
        controlName: 'registrationNumber',
        messages: {
          required: 'Informe a matrícula',
        },
      },
      cnpj: {
        label: 'CNPJ',
        type: InputType.TEL,
        mask: cnpjMask,
        isNumber: true,
        controlName: 'cnpj',
        messages: {
          invalidCnpj: 'CNPJ inválido',
          required: 'Informe o cnpj',
        },
      },
      formCnae: {
        label: 'CNAE',
        items: [],
        searchable: true,
        searchPlaceholder: 'Digite o CNAE',
        controlName: 'formCnae',
        messages: {
          required: 'Digite o CNAE',
          invalid: 'CNAE inválido',
        },
      },

      annualRevenue: {
        label: 'Faturamento anual no cartão',
        controlName: 'annualRevenue',
        messages: {
          required: 'Informe o faturamento anual',
          min: 'O valor mínimo deve ser maior que R$120.000,00',
          max: 'O valor máximo não deve ultrapassar R$999.999.999,99',
        },
      },
      agencyName: {
        label: 'Número da agência',
        controlName: 'agencyName',
        maxLength: 4,
        mask: OnlyNumberWithZeroMask,
        messages: {
          required: 'Informe o Número da agência',
        },
      },
      email: {
        label: 'E-mail',
        controlName: 'email',
        messages: {
          required: 'Informe o email',
          pattern: 'E-mail inválido',
        },
        disabled: false

      },
      socialReason: {
        label: 'Razão social',
        controlName: 'socialReason',
        messages: {
          required: 'Informe a razão social',
        },
      },
      prepaymentEnabled: {
        label: 'Deseja antecipar?',
        controlName: 'prepaymentEnabled',
        items: this.yesNoItems,
        messages: {
          required: 'Informe uma opção',
        },
      },
      posAmount: {
        label: 'Quantidade de máquinas',
        maxLength: 5,
        controlName: 'posAmount',
        mask: OnlyNumberMask,
        messages: {
          required: 'Informe a quantidade',
          lessThan: 'Não deve ser menor que a quantidade de isenções.',
          greaterThan: 'Não deve ser menor que a quantidade de isenções.',
        },
      },
      terminalUnitPrice: {
        label: 'Valor por terminal',
        maxLength: 6,
        controlName: 'terminalUnitPrice',
        messages: {
          required: 'Informe o valor',
        },
      },
      requestedExceptions: {
        label: 'Isenções solicitadas',
        maxLength: 5,
        controlName: 'requestedExceptions',
        mask: OnlyNumberMask,
        messages: {
          required: 'Informe um valor',
          lessThan: 'Não deve ser maior que a quantidade de máquina.',
          greaterThan: 'Não deve ser maior que a quantidade de máquina.',
        },
      },
      revenuePercentCredit: {
        label: 'Crédito',
        maxLength: 3,
        controlName: 'revenuePercentCredit',
        isPercentNoDecimal: true,
        messages: {
          required: 'Informe um valor',
          min: 'O valor mínimo deve ser maior que 0,00%',
          max: 'O valor máximo não deve ultrapassar 100,00%',
          equalsSumValues: 'A distribuição do faturamento por produto não poderá ser diferente de 100%',
        },
      },
      revenuePercentDebit: {
        label: 'Débito',
        isPercentNoDecimal: true,
        maxLength: 3,
        controlName: 'revenuePercentDebit',
        messages: {
          required: 'Informe um valor',
          min: 'O valor mínimo deve ser maior que 0,00%',
          max: 'O valor máximo não deve ultrapassar 100,00%',
          equalsSumValues: 'A distribuição do faturamento por produto não poderá ser diferente de 100%',
        },
      },
      revenuePercent2to6: {
        label: 'Parcelado 2 a 6 vezes',
        isPercentNoDecimal: true,
        maxLength: 3,
        controlName: 'revenuePercent2to6',
        messages: {
          required: 'Informe um valor',
          min: 'O valor mínimo deve ser maior que 0,00%',
          max: 'O valor máximo não deve ultrapassar 100,00%',
          equalsSumValues: 'A distribuição do faturamento por produto não poderá ser diferente de 100%',
        },
      },
      revenuePercent7to12: {
        label: 'Parcelado 7 a 12 vezes',
        isPercentNoDecimal: true,
        maxLength: 3,
        controlName: 'revenuePercent7to12',
        messages: {
          required: 'Informe um valor',
          min: 'O valor mínimo deve ser maior que 0,00%',
          max: 'O valor máximo não deve ultrapassar 100,00%',
          equalsSumValues: 'A distribuição do faturamento por produto não poderá ser diferente de 100%',
        },
      },
      suggestedFeesCredit: {
        label: 'Crédito',
        isPercent: true,
        maxLength: 3,
        controlName: 'suggestedFeesCredit',
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },
      suggestedFeesDebit: {
        label: 'Débito',
        isPercent: true,
        maxLength: 3,
        controlName: 'suggestedFeesDebit',
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },
      suggestedFees2to6: {
        label: 'Parcelado 2 a 6 vezes',
        controlName: 'suggestedFees2to6',
        isPercent: true,
        maxLength: 3,
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },
      suggestedFees7to12: {
        isPercent: true,
        label: 'Parcelado 7 a 12 vezes',
        controlName: 'suggestedFees7to12',
        maxLength: 3,
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },

      suggestedEloOthersFeesCredit: {
        label: 'Crédito',
        isPercent: true,
        maxLength: 3,
        controlName: 'suggestedEloOthersFeesCredit',
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },
      suggestedEloOthersFeesDebit: {
        label: 'Débito',
        isPercent: true,
        maxLength: 3,
        controlName: 'suggestedEloOthersFeesDebit',
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },
      suggestedEloOthersFees2to6: {
        label: 'Parcelado 2 a 6 vezes',
        controlName: 'suggestedEloOthersFees2to6',
        isPercent: true,
        maxLength: 3,
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },
      suggestedEloOthersFees7to12: {
        isPercent: true,
        label: 'Parcelado 7 a 12 vezes',
        controlName: 'suggestedEloOthersFees7to12',
        maxLength: 3,
        messages: {
          required: 'Informe um valor',
          max: 'O valor máximo não deve ultrapassar 9,99%',
        },
      },
    }
  }

  public createFormGroup() {
    return this.formBuilder.group({
      annualRevenue: [{value: '', disabled:true}, [Validators.required, Validators.min(120000.01), Validators.max(999999999)]],
      registrationNumber: [{value: '', disabled:true}, Validators.required],
      cnpj: [{value: '', disabled:true}, [Validators.required, CnpjValidator]],
      formCnae: [{value: '', disabled:true}, Validators.required],
      agencyName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EmailRegExp)]],
      socialReason: ['', Validators.required],
      prepaymentEnabled: ['', Validators.required],
      posAmount: [''],
      terminalUnitPrice: ['', Validators.required],
      requestedExceptions: [''],
      revenuePercentCredit: [''],
      revenuePercentDebit: [''],
      revenuePercent2to6: ['', [Validators.required, Validators.max(100.0)]],
      revenuePercent7to12: ['', [Validators.required, Validators.max(100.0)]],
      suggestedFeesCredit: ['', [Validators.required, Validators.min(0)]],
      suggestedFeesDebit: ['', [Validators.required, Validators.min(0)]],
      suggestedFees2to6: ['', [Validators.required, Validators.min(0)]],
      suggestedFees7to12: ['', [Validators.required, Validators.min(0)]],

      suggestedEloOthersFeesCredit: ['', [Validators.required, Validators.min(0)]],
      suggestedEloOthersFeesDebit: ['', [Validators.required, Validators.min(0)]],
      suggestedEloOthersFees2to6: ['', [Validators.required, Validators.min(0)]],
      suggestedEloOthersFees7to12: ['', [Validators.required, Validators.min(0)]],
    })
  }

  private appendValidators() {
      this.formControls.posAmount.setValidators([
        Validators.required,
        CompareValidatorNumberConvert(this.formControls.requestedExceptions, CompareTypeEnum.GREATER_THAN),
      ]);

      this.formControls.requestedExceptions.setValidators([
        Validators.required,
        CompareValidatorNumberConvert(this.formControls.posAmount, CompareTypeEnum.LESS_THAN),
      ]);

      this.formControls.revenuePercentCredit.setValidators([
        Validators.required,
        EqualsSumValidator(
          this.formControls.revenuePercentCredit,
          [this.formControls.revenuePercentCredit, this.formControls.revenuePercentDebit, this.formControls.revenuePercent2to6, this.formControls.revenuePercent7to12],
          100
        ),
      ]);

      this.formControls.revenuePercentDebit.setValidators([
        Validators.required,
        EqualsSumValidator(
          this.formControls.revenuePercentDebit,
          [this.formControls.revenuePercentCredit, this.formControls.revenuePercentDebit, this.formControls.revenuePercent2to6, this.formControls.revenuePercent7to12],
          100
        ),
      ]);

      this.formControls.revenuePercent2to6.setValidators([
        Validators.required,
        EqualsSumValidator(
          this.formControls.revenuePercent2to6,
          [this.formControls.revenuePercentCredit, this.formControls.revenuePercentDebit, this.formControls.revenuePercent2to6, this.formControls.revenuePercent7to12],
          100
        ),
      ]);

      this.formControls.revenuePercent7to12.setValidators([
        Validators.required,
        EqualsSumValidator(
          this.formControls.revenuePercent7to12,
          [this.formControls.revenuePercentCredit, this.formControls.revenuePercentDebit, this.formControls.revenuePercent2to6, this.formControls.revenuePercent7to12],
          100
        ),
      ]);
  }

  public get formIsInvalid(): boolean {
    return this.formGroup.invalid || this.emailIsEmpty;
  }

  private get emailIsEmpty(): boolean {
    return this.formControls.email.value == null || this.formControls.email.value == '';
  }

  private get registrationNumberIsEmpty(): boolean {
    return this.formControls.registrationNumber.value == null
      || this.formControls.registrationNumber.value == ''
      || this.formControls.registrationNumber.value.toLowerCase() == 'null';
  }

}
